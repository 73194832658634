import axios from "./index";
const callComplaintsAPI = (headers, parameter, successCallback, failureCallback) => {
    axios.get("/api/v1/complaints", {
        headers: headers,
        params: {
            corporation_id: parameter.corporationId,
            target_month: parameter.targetMonth.toString()
        }
    }).then((response) => {
        successCallback(response.headers, response.data["complaints"], response.data["published"]);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
const callDownloadComplaintsAPI = (headers, parameter, successCallback, failureCallback) => {
    axios.get("/api/v1/complaints.csv", {
        headers: headers,
        responseType: "blob",
        params: {
            corporation_id: parameter.corporationId,
            target_month: parameter.targetMonth.toString(),
        }
    }).then((response) => {
        const mineType = response.headers["content-type"];
        const blob = new Blob([response.data], { type: mineType });
        successCallback(response.headers, blob);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
const callComplaintsBulkUpdateAPI = (headers, params, successCallback, failureCallback) => {
    axios.put("/api/v1/complaints/bulk_update", {
        complaints: params
    }, {
        headers: headers,
    }).then((response) => {
        successCallback(response.headers, response.status);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
export { callComplaintsAPI, callDownloadComplaintsAPI, callComplaintsBulkUpdateAPI };
