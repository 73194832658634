export const ASSIGN_AUTH_HEADERS = "ASSIGN_AUTH_HEADERS";
export const ASSIGN_CURRENT_USER = "ASSIGN_CURRENT_USER";
export const ASSIGN_CORPORATIONS_IN_CHARGE = "ASSIGN_CORPORATIONS_IN_CHARGE";
export const ASSIGN_CORPORATIONS_NESTED_TREE = "ASSIGN_CORPORATIONS_NESTED_TREE";
export const ASSIGN_NORMAL_CORPORATIONS_COUNT = "ASSIGN_NORMAL_CORPORATIONS_COUNT";
export const ASSIGN_ALL_REGISTRATION_COUNT = "ASSIGN_ALL_REGISTRATION_COUNT";
export const ASSIGN_TARGET_MONTH_REGISTRATION_COUNT = "ASSIGN_TARGET_MONTH_REGISTRATION_COUNT";
export const ASSIGN_TARGET_MONTH_SYOUNIKA_REGISTRATION_COUNT = "ASSIGN_TARGET_MONTH_SYOUNIKA_REGISTRATION_COUNT";
export const ASSIGN_TARGET_MONTH_OBSTETRICS_REGISTRATION_COUNT = "ASSIGN_TARGET_MONTH_OBSTETRICS_REGISTRATION_COUNT";
export const ASSIGN_SELECTED_CORPORATION_ID = "ASSIGN_SELECTED_CORPORATION_ID";
export const ASSIGN_SELECTED_HOME_DATE = "ASSIGN_SELECTED_HOME_DATE";
export const ASSIGN_SELECTED_YEARLY_SUMMARY_START_DATE = "ASSIGN_SELECTED_YEARLY_SUMMARY_START_DATE";
export const ASSIGN_SELECTED_YEARLY_SUMMARY_END_DATE = "ASSIGN_SELECTED_YEARLY_SUMMARY_END_DATE";
export const ASSIGN_USAGES_COUNT = "ASSIGN_USAGES_COUNT";
export const ASSIGN_LATEST_MEDICAL_INFORMATIONS = "ASSIGN_LATEST_MEDICAL_INFORMATIONS";
export const ASSIGN_MONTHLY_MEDICAL_INFORMATIONS = "ASSIGN_MONTHLY_MEDICAL_INFORMATIONS";
export const ASSIGN_SELECTED_MEDICAL_INFORMATION_DATE = "ASSIGN_SELECTED_MEDICAL_INFORMATION_DATE";
export const ASSIGN_MONTHLY_REPORT_MAIL = "ASSIGN_MONTHLY_REPORT_MAIL";
export const ASSIGN_POST_MONTHLY_REPORT_MAIL_RESULT = "ASSIGN_POST_MONTHLY_REPORT_MAIL_RESULT";
export const ASSIGN_SELECTED_MONTHLY_REPORT_MAIL_DATE = "ASSIGN_SELECTED_MONTHLY_REPORT_MAIL_DATE";
export const ASSIGN_MONTHLY_REPORT_MAIL_EDITABLE = "ASSIGN_MONTHLY_REPORT_MAIL_EDITABLE";
export const ASSIGN_MONTHLY_REPORT_MAIL_ERRORS = "ASSIGN_MONTHLY_REPORT_MAIL_ERRORS";
export const ASSIGN_CORPORATION = "ASSIGN_CORPORATION";
export const ASSIGN_MONTHLY_MAIL_RECIPIENTS = "ASSIGN_MONTHLY_MAIL_RECIPIENTS";
export const ASSIGN_SALES_MANAGERS = "ASSIGN_SALES_MANAGERS";
export const ASSIGN_COMPLAINTS = "ASSIGN_COMPLAINTS";
export const ASSIGN_SELECTED_COMPLAINTS_DATE = "ASSIGN_SELECTED_COMPLAINTS_DATE";
export const ASSIGN_COMPLAINTS_PUBLISHED = "ASSIGN_COMPLAINTS_PUBLISHED";
export const ASSIGN_COMPLAINT_PUBLICATION_AVAILABILITY = "ASSIGN_COMPLAINT_PUBLICATION_AVAILABILITY";
export const ASSIGN_AFTER_USE_QUESTIONNAIRES = "ASSIGN_AFTER_USE_QUESTIONNAIRES";
export const ASSIGN_SELECTED_QUESTIONNAIRE_DATE = "ASSIGN_SELECTED_QUESTIONNAIRE_DATE";
export const ASSIGN_AFTER_USE_QUESTIONNAIRES_PUBLISHED = "ASSIGN_AFTER_USE_QUESTIONNAIRES_PUBLISHED";
export const ASSIGN_AFTER_USE_QUESTIONNAIRE_PUBLICATION_AVAILABILITY = "ASSIGN_AFTER_USE_QUESTIONNAIRE_PUBLICATION_AVAILABILITY";
export const ASSIGN_SELECTED_QUESTIONNAIRE_ANALYTICS_DATE = "ASSIGN_SELECTED_QUESTIONNAIRE_ANALYTICS_DATE";
export const ASSIGN_ANYTIME_CONSULTATION_QUESTIONNAIRE_COUNT = "ASSIGN_ANYTIME_CONSULTATION_QUESTIONNAIRE_COUNT";
export const ASSIGN_NIGHT_CONSULTATION_QUESTIONNAIRE_COUNT = "ASSIGN_NIGHT_CONSULTATION_QUESTIONNAIRE_COUNT";
export const ASSIGN_SELECTED_MEDICINE_QUESTION_ANALYTICS_DATE = "ASSIGN_SELECTED_MEDICINE_QUESTION_ANALYTICS_DATE";
export const ASSIGN_MEDICINE_QUESTION_USAGE_ALL_COUNT = "ASSIGN_MEDICINE_QUESTION_USAGE_ALL_COUNT";
export const ASSIGN_MEDICINE_QUESTION_PREGNANT_USAGES = "ASSIGN_MEDICINE_QUESTION_PREGNANT_USAGES";
export const ASSIGN_MEDICINE_QUESTION_AFTER_CHILDBIRTH_USAGES = "ASSIGN_MEDICINE_QUESTION_AFTER_CHILDBIRTH_USAGES";
export const ASSIGN_MEDICINE_QUESTION_QUICK_SURVEYS = "ASSIGN_MEDICINE_QUESTION_QUICK_SURVEYS";
export const ASSIGN_DAYTIME_ADVICE_QUESTIONNAIRE_COUNT = "ASSIGN_DAYTIME_ADVICE_QUESTIONNAIRE_COUNT";
export const ASSIGN_YEARLY_SUMMARY = "ASSIGN_YEARLY_SUMMARY";
export const ASSIGN_SELECTED_TASK_STATUS_DATE = "ASSIGN_SELECTED_TASK_STATUS_DATE";
export const ASSIGN_COMPLAINT_PUBLISHED_STATUS = "ASSIGN_COMPLAINT_PUBLISHED_STATUS";
export const ASSIGN_QUESTIONNAIRE_PUBLISHED_STATUS = "ASSIGN_QUESTIONNAIRE_PUBLISHED_STATUS";
export const ASSIGN_MAIL_SEND_STATUS = "ASSIGN_MAIL_SEND_STATUS";
export const CREATE_TASK_STATUS_OBJECT = "CREATE_TASK_STATUS_OBJECT";
export const ASSIGN_COMPLAINT_PUBLISHED_STAUS_COUNT = "ASSIGN_COMPLAINT_PUBLISHED_STAUS_COUNT";
export const ASSIGN_QUESTIONNAIRE_PUBLISHED_STATUS_COUNT = "ASSIGN_QUESTIONNAIRE_PUBLISHED_STATUS_COUNT";
export const ASSIGN_MAIL_SEND_STATUS_COUNT = "ASSIGN_MAIL_SEND_STATUS_COUNT";
// store reset
export const RESET_AFTER_USE_QUESTIONNAIRE_PUBLICATION_AVAILABILITIES_STATE = "RESET_AFTER_USE_QUESTIONNAIRE_PUBLICATION_AVAILABILITIES_STATE";
export const RESET_AFTER_USE_QUESTIONNAIRES_STATE = "RESET_AFTER_USE_QUESTIONNAIRES_STATE";
export const RESET_COMPLAINT_PUBLIC_AVAILABILITIES_STATE = "RESET_COMPLAINT_PUBLIC_AVAILABILITIES_STATE";
export const RESET_COMPLAINTS_STATE = "RESET_COMPLAINTS_STATE";
export const RESET_CORPORATIONS_STATE = "RESET_CORPORATIONS_STATE";
export const RESET_CURRENT_USER_STATE = "RESET_CURRENT_USER_STATE";
export const RESET_MANAGERS_STATE = "RESET_MANAGERS_STATE";
export const RESET_MEDICAL_INFORMATIONS_STATE = "RESET_MEDICAL_INFORMATIONS_STATE";
export const RESET_MONTHLY_REPORT_MAILS_STATE = "RESET_MONTHLY_REPORT_MAILS_STATE";
export const RESET_QUESTIONNAIRE_COUNT_STATE = "RESET_QUESTIONNAIRE_COUNT_STATE";
export const RESET_SELECTED_VALUES_STATE = "RESET_SELECTED_VALUES_STATE";
export const RESET_RESPONSE_RESULT_STATE = "RESET_RESPONSE_RESULT_STATE";
export const RESET_MEDICINE_QUESTION_ANALYTICS_STATE = "RESET_MEDICINE_QUESTION_ANALYTICS_STATE";
export const RESET_TASK_STATUSES_STATE = "RESET_TASK_STATUSES_STATE";
