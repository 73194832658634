import { defineComponent, computed, onMounted } from "vue";
import { useStore } from "../stores/index";
import monthPicker from "./shared/MonthPicker.vue";
import taskStatusRow from "./shared/TaskStatusRow.vue";
export default defineComponent({
    name: "TaskStatusPage",
    components: {
        monthPicker,
        taskStatusRow
    },
    setup() {
        // vuex
        const store = useStore();
        // data
        const selectedDate = computed(() => new Date(store.getters.selectedTaskStatusDate));
        const taskStatuses = computed(() => store.getters.taskStatuses);
        const corporations = computed(() => store.getters.corporationsNestedTree);
        const normalCorporationsCount = computed(() => store.getters.normalCorporationsCount);
        const complaintPublishedStausCount = computed(() => store.getters.complaintPublishedStausCount);
        const questionnairePublishedStatusCount = computed(() => store.getters.questionnairePublishedStatusCount);
        const mailSendStatusCount = computed(() => store.getters.mailSendStatusCount);
        const overlay = computed(() => complaintPublishedStausCount.value != normalCorporationsCount.value
            || questionnairePublishedStatusCount.value != normalCorporationsCount.value
            || mailSendStatusCount.value != normalCorporationsCount.value);
        const saveSelectedDate = async (selectedValue) => {
            await store.dispatch("commitSelectedTaskStatusDate", selectedValue);
        };
        const downloadCSV = async () => {
            await store.dispatch("downloadTaskStatus");
        };
        onMounted(() => {
            store.dispatch("commitSelectedTaskStatusDate", selectedDate.value);
        });
        return {
            selectedDate,
            taskStatuses,
            corporations,
            overlay,
            saveSelectedDate,
            downloadCSV,
        };
    }
});
