import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_datepicker = _resolveComponent("datepicker");
    return (_openBlock(), _createBlock(_component_datepicker, {
        modelValue: _ctx.selectedValue,
        "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event) => ((_ctx.selectedValue) = $event)),
            _ctx.onMonthChange
        ],
        class: "pa-2 month-picker",
        "starting-view": "month",
        "minimum-view": "month",
        "upper-limit": _ctx.upperLimit,
        "lower-limit": _ctx.lowerLimit,
        "input-format": "yyyy年MM月       ▼",
        locale: _ctx.ja
    }, null, 8, ["modelValue", "upper-limit", "lower-limit", "locale", "onUpdate:modelValue"]));
}
