import { defineComponent, onMounted, computed } from "vue";
import { useStore } from "../../stores/index";
export default defineComponent({
    name: "LatestMedicalInformationsPart",
    setup() {
        const store = useStore();
        // data
        const latestMedicalInformations = computed(() => store.getters.latestMedicalInformations);
        onMounted(() => {
            store.dispatch("fetchLatestMedicalInformations");
        });
        return {
            latestMedicalInformations
        };
    }
});
