import * as MutationTypes from "../mutation_types";
import { callComplaintsAPI, callDownloadComplaintsAPI, callComplaintsBulkUpdateAPI } from "../../api/complaints";
import { HTTP_STATUS_NO_CONTENT } from "../../api/index";
import { saveAs } from "file-saver";
const getDefaultState = () => {
    return {
        complaints: [],
        complaintsPublished: false
    };
};
const complaints = {
    state: () => getDefaultState(),
    getters: {
        complaints(state) {
            return state.complaints;
        },
        complaintsPublished(state) {
            return state.complaintsPublished;
        },
    },
    actions: {
        fetchComplaints({ dispatch, commit, getters }) {
            return new Promise((resolve, reject) => {
                callComplaintsAPI(getters.authHeaders, {
                    corporationId: getters.selectedCorporationId,
                    targetMonth: getters.selectedComplaintsDate
                }, 
                // Success
                (headers, complaints, complaintsPublished) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    commit(MutationTypes.ASSIGN_COMPLAINTS, complaints);
                    dispatch("fetchComplaintsPublished", complaintsPublished);
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        downloadComplaints({ commit, getters }) {
            return new Promise((resolve, reject) => {
                callDownloadComplaintsAPI(getters.authHeaders, {
                    corporationId: getters.selectedCorporationId,
                    targetMonth: getters.selectedComplaintsDate
                }, 
                // Success
                (headers, csvData) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    const targetMonth = getters.selectedComplaintsDate;
                    saveAs(csvData, `主訴一覧_${targetMonth.getFullYear()}年${targetMonth.getMonth() + 1}月.csv`);
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        updateComplaints({ dispatch, commit, getters }, params) {
            return new Promise((resolve, reject) => {
                callComplaintsBulkUpdateAPI(getters.authHeaders, params, 
                // Success
                (headers, status) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    if (status == HTTP_STATUS_NO_CONTENT) {
                        dispatch("fetchComplaints");
                    }
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        fetchComplaintsPublished({ commit }, published) {
            return new Promise((resolve) => {
                commit(MutationTypes.ASSIGN_COMPLAINTS_PUBLISHED, published);
                resolve();
            });
        },
        resetComplaintsState({ commit }) {
            commit(MutationTypes.RESET_COMPLAINTS_STATE);
        },
    },
    mutations: {
        [MutationTypes.ASSIGN_COMPLAINTS](state, complaints) {
            state.complaints = complaints;
        },
        [MutationTypes.ASSIGN_COMPLAINTS_PUBLISHED](state, complaintsPublished) {
            state.complaintsPublished = complaintsPublished;
        },
        [MutationTypes.RESET_COMPLAINTS_STATE](state) {
            Object.assign(state, getDefaultState());
        },
    }
};
export default complaints;
