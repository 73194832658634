import * as MutationTypes from "../mutation_types";
import { callAfterUseQuestionnairesAPI, callDownloadAfterUseQuestionnairesAPI, callAfterUseQuestionnairesBulkUpdateAPI } from "../../api/after_use_questionnaires";
import { HTTP_STATUS_NO_CONTENT } from "../../api/index";
import { saveAs } from "file-saver";
const getDefaultState = () => {
    return {
        afterUseQuestionnaires: [],
        afterUseQuestionnairesPublished: false
    };
};
const afterUseQuestionnaires = {
    state: () => getDefaultState(),
    getters: {
        afterUseQuestionnaires(state) {
            return state.afterUseQuestionnaires;
        },
        afterUseQuestionnairesPublished(state) {
            return state.afterUseQuestionnairesPublished;
        },
    },
    actions: {
        fetchAfterUseQuestionnaires({ dispatch, commit, getters }) {
            return new Promise((resolve, reject) => {
                callAfterUseQuestionnairesAPI(getters.authHeaders, {
                    corporationId: getters.selectedCorporationId,
                    targetMonth: getters.selectedQuestionnaireDate
                }, 
                // Success
                (headers, afterUseQuestionnaires, afterUseQuestionnairesPublished) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    commit(MutationTypes.ASSIGN_AFTER_USE_QUESTIONNAIRES, afterUseQuestionnaires);
                    dispatch("fetchAfterUseQuestionnairesPublished", afterUseQuestionnairesPublished);
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        downloadAfterUseQuestionnaires({ commit, getters }) {
            return new Promise((resolve, reject) => {
                callDownloadAfterUseQuestionnairesAPI(getters.authHeaders, {
                    corporationId: getters.selectedCorporationId,
                    targetMonth: getters.selectedQuestionnaireDate
                }, 
                // Success
                (headers, csvData) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    const targetMonth = getters.selectedQuestionnaireDate;
                    saveAs(csvData, `利用後アンケートフリー回答一覧_${targetMonth.getFullYear()}年${targetMonth.getMonth() + 1}月.csv`);
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        updateAfterUseQuestionnaires({ dispatch, commit, getters }, params) {
            return new Promise((resolve, reject) => {
                callAfterUseQuestionnairesBulkUpdateAPI(getters.authHeaders, params, 
                // Success
                (headers, status) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    if (status == HTTP_STATUS_NO_CONTENT) {
                        dispatch("fetchAfterUseQuestionnaires");
                    }
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        fetchAfterUseQuestionnairesPublished({ commit }, published) {
            return new Promise((resolve) => {
                commit(MutationTypes.ASSIGN_AFTER_USE_QUESTIONNAIRES_PUBLISHED, published);
                resolve();
            });
        },
        resetAfterUseQuestionnairesState({ commit }) {
            commit(MutationTypes.RESET_AFTER_USE_QUESTIONNAIRES_STATE);
        },
    },
    mutations: {
        [MutationTypes.ASSIGN_AFTER_USE_QUESTIONNAIRES](state, afterUseQuestionnaires) {
            state.afterUseQuestionnaires = afterUseQuestionnaires;
        },
        [MutationTypes.ASSIGN_AFTER_USE_QUESTIONNAIRES_PUBLISHED](state, afterUseQuestionnairesPublished) {
            state.afterUseQuestionnairesPublished = afterUseQuestionnairesPublished;
        },
        [MutationTypes.RESET_AFTER_USE_QUESTIONNAIRES_STATE](state) {
            Object.assign(state, getDefaultState());
        },
    }
};
export default afterUseQuestionnaires;
