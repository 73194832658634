import axios from "./index";
const callMedicineQuestionAnalyticsAPI = (headers, parameter, successCallback, failureCallback) => {
    axios.get("/api/v1/medicine_question_analytics", {
        headers: headers,
        params: {
            corporation_id: parameter.corporationId,
            target_month: parameter.targetMonth.toString()
        }
    }).then((response) => {
        successCallback(response.headers, response.data["countOfUsages"]["allCount"], response.data["countOfUsages"]["pregnant"], response.data["countOfUsages"]["afterChildbirth"], response.data["countOfUsages"]["quickSurveys"]);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
export { callMedicineQuestionAnalyticsAPI };
