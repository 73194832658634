import * as MutationTypes from "../mutation_types";
import { afterUseQuestionnairePublicAvailabilitiesCreateAPI, afterUseQuestionnairePublicAvailabilityDestroyAPI, callAfterUseQuestionnairePublicAvailabilityAPI } from "../../api/after_use_questionnaire_public_availabilities";
import { HTTP_STATUS_NO_CONTENT } from "../../api/index";
const getDefaultState = () => {
    return {
        afterUseQuestionnairePublicAvailability: null
    };
};
const afterUseQuestionnairePublicAvailabilities = {
    state: () => getDefaultState(),
    getters: {
        afterUseQuestionnairePublicAvailability(state) {
            return state.afterUseQuestionnairePublicAvailability;
        }
    },
    actions: {
        createAfterUseQuestionnairePublicAvailability({ dispatch, commit, getters }) {
            return new Promise((resolve, reject) => {
                afterUseQuestionnairePublicAvailabilitiesCreateAPI(getters.authHeaders, {
                    corporationId: getters.selectedCorporationId,
                    targetMonth: getters.selectedQuestionnaireDate
                }, 
                // Success
                (headers, publicAvailability) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    dispatch("fetchAfterUseQuestionnairesPublished", true);
                    commit(MutationTypes.ASSIGN_AFTER_USE_QUESTIONNAIRE_PUBLICATION_AVAILABILITY, publicAvailability);
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        deleteAfterUseQuestionnairePublicAvailability({ dispatch, commit, getters }, afterUseQuestionnairePublicAvailabilityId) {
            return new Promise((resolve, reject) => {
                afterUseQuestionnairePublicAvailabilityDestroyAPI(getters.authHeaders, afterUseQuestionnairePublicAvailabilityId, 
                // Success
                (headers, status) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    if (status == HTTP_STATUS_NO_CONTENT) {
                        dispatch("fetchAfterUseQuestionnairesPublished", false);
                        commit(MutationTypes.ASSIGN_AFTER_USE_QUESTIONNAIRE_PUBLICATION_AVAILABILITY, null);
                    }
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        fetchAfterUseQuestionnairePublicAvailability({ commit, getters }) {
            return new Promise((resolve, reject) => {
                callAfterUseQuestionnairePublicAvailabilityAPI(getters.authHeaders, {
                    corporationId: getters.selectedCorporationId,
                    targetMonth: getters.selectedQuestionnaireDate
                }, 
                // Success
                (headers, publicAvailability) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    commit(MutationTypes.ASSIGN_AFTER_USE_QUESTIONNAIRE_PUBLICATION_AVAILABILITY, publicAvailability);
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        resetAfterUseQuestionnairePublicAvailabilitiesState({ commit }) {
            commit(MutationTypes.RESET_AFTER_USE_QUESTIONNAIRE_PUBLICATION_AVAILABILITIES_STATE);
        },
    },
    mutations: {
        [MutationTypes.ASSIGN_AFTER_USE_QUESTIONNAIRE_PUBLICATION_AVAILABILITY](state, afterUseQuestionnairePublicAvailability) {
            state.afterUseQuestionnairePublicAvailability = afterUseQuestionnairePublicAvailability;
        },
        [MutationTypes.RESET_AFTER_USE_QUESTIONNAIRE_PUBLICATION_AVAILABILITIES_STATE](state) {
            Object.assign(state, getDefaultState());
        },
    }
};
export default afterUseQuestionnairePublicAvailabilities;
