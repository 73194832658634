import { defineComponent } from "vue";
import latestMedicalInformations from "./shared/LatestMedicalInformations.vue";
import responseResults from "./shared/ResponseResults.vue";
export default defineComponent({
    name: "MainPage",
    components: {
        latestMedicalInformations,
        responseResults
    },
});
