import * as MutationTypes from "../mutation_types";
import { complaintPublicAvailabilitiesCreateAPI, complaintPublicAvailabilityDestroyAPI, callComplaintPublicAvailabilityAPI } from "../../api/complaint_public_availabilities";
import { HTTP_STATUS_NO_CONTENT } from "../../api/index";
const getDefaultState = () => {
    return {
        complaintPublicAvailability: null
    };
};
const complaintPublicAvailabilities = {
    state: () => getDefaultState(),
    getters: {
        complaintPublicAvailability(state) {
            return state.complaintPublicAvailability;
        }
    },
    actions: {
        createComplaintPublicAvailability({ dispatch, commit, getters }) {
            return new Promise((resolve, reject) => {
                complaintPublicAvailabilitiesCreateAPI(getters.authHeaders, {
                    corporationId: getters.selectedCorporationId,
                    targetMonth: getters.selectedComplaintsDate
                }, 
                // Success
                (headers, publicAvailability) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    dispatch("fetchComplaintsPublished", true);
                    commit(MutationTypes.ASSIGN_COMPLAINT_PUBLICATION_AVAILABILITY, publicAvailability);
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        deleteComplaintPublicAvailability({ dispatch, commit, getters }, complaintPublicAvailabilityId) {
            return new Promise((resolve, reject) => {
                complaintPublicAvailabilityDestroyAPI(getters.authHeaders, complaintPublicAvailabilityId, 
                // Success
                (headers, status) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    if (status == HTTP_STATUS_NO_CONTENT) {
                        dispatch("fetchComplaintsPublished", false);
                        commit(MutationTypes.ASSIGN_COMPLAINT_PUBLICATION_AVAILABILITY, null);
                    }
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        fetchComplaintPublicAvailability({ commit, getters }) {
            return new Promise((resolve, reject) => {
                callComplaintPublicAvailabilityAPI(getters.authHeaders, {
                    corporationId: getters.selectedCorporationId,
                    targetMonth: getters.selectedComplaintsDate
                }, 
                // Success
                (headers, publicAvailability) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    commit(MutationTypes.ASSIGN_COMPLAINT_PUBLICATION_AVAILABILITY, publicAvailability);
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        resetComplaintPublicAvailabilitiesState({ commit }) {
            commit(MutationTypes.RESET_COMPLAINT_PUBLIC_AVAILABILITIES_STATE);
        },
    },
    mutations: {
        [MutationTypes.ASSIGN_COMPLAINT_PUBLICATION_AVAILABILITY](state, publicAvailability) {
            state.complaintPublicAvailability = publicAvailability;
        },
        [MutationTypes.RESET_COMPLAINT_PUBLIC_AVAILABILITIES_STATE](state) {
            Object.assign(state, getDefaultState());
        },
    }
};
export default complaintPublicAvailabilities;
