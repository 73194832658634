import sanitizeHtml from "sanitize-html";
const textFormatter = () => {
    const newLineCodeToBr = (str) => {
        if (str === null) {
            return "";
        }
        return sanitizeHtml(str.replace(/\r?\n/g, "<br>"));
    };
    return {
        newLineCodeToBr
    };
};
export { textFormatter };
