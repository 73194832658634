import * as MutationTypes from "../mutation_types";
import { dateUtility } from "../../service/date_utility";
const getDefaultState = () => {
    const today = new Date;
    const prevMonth = dateUtility().prevMonth();
    const selectedYearlySummaryStartDate = new Date(dateUtility().fiscalYear(), 3, 1); // 前月が含まれる年度の最初の月(4月）
    const selectedYearlySummaryEndDate = prevMonth; // 前月
    return {
        selectedCorporationId: -1,
        selectedHomeDate: today,
        selectedMedicalInformationDate: today,
        selectedMonthlyReportMailDate: today,
        selectedComplaintsDate: today,
        selectedQuestionnaireDate: today,
        selectedQuestionnaireAnalyticsDate: today,
        selectedMedicineQuestionAnalyticsDate: today,
        selectedYearlySummaryStartDate: selectedYearlySummaryStartDate,
        selectedYearlySummaryEndDate: selectedYearlySummaryEndDate,
        selectedTaskStatusDate: prevMonth
    };
};
const selectedValues = {
    state: () => getDefaultState(),
    getters: {
        selectedCorporationId(state) {
            return state.selectedCorporationId;
        },
        selectedHomeDate(state) {
            return state.selectedHomeDate;
        },
        selectedMedicalInformationDate(state) {
            return state.selectedMedicalInformationDate;
        },
        selectedMonthlyReportMailDate(state) {
            return state.selectedMonthlyReportMailDate;
        },
        selectedComplaintsDate(state) {
            return state.selectedComplaintsDate;
        },
        selectedQuestionnaireDate(state) {
            return state.selectedQuestionnaireDate;
        },
        selectedQuestionnaireAnalyticsDate(state) {
            return state.selectedQuestionnaireAnalyticsDate;
        },
        selectedMedicineQuestionAnalyticsDate(state) {
            return state.selectedMedicineQuestionAnalyticsDate;
        },
        selectedYearlySummaryStartDate(state) {
            return state.selectedYearlySummaryStartDate;
        },
        selectedYearlySummaryEndDate(state) {
            return state.selectedYearlySummaryEndDate;
        },
        selectedTaskStatusDate(state) {
            return state.selectedTaskStatusDate;
        },
    },
    actions: {
        commitSelectedCorporationId({ dispatch, commit }, selectedCorporationId) {
            commit(MutationTypes.ASSIGN_SELECTED_CORPORATION_ID, selectedCorporationId);
            if (selectedCorporationId !== -1) {
                // 企業を切り替えたときに取得すべき情報を再取得
                dispatch("fetchCorporation");
                dispatch("fetchTotalRegistrationCounts");
                dispatch("fetchRegistrationCounts");
                dispatch("fetchUsageCounts");
                dispatch("fetchMonthlyReportMail");
                dispatch("fetchMonthlyMailRecipients");
                dispatch("fetchComplaints");
                dispatch("fetchAfterUseQuestionnaires");
                dispatch("fetchComplaintPublicAvailability");
                dispatch("fetchAfterUseQuestionnairePublicAvailability");
                dispatch("fetchAnytimeConsultationCount");
                dispatch("fetchNightConsultationCount");
                dispatch("fetchDaytimeAdviceCount");
                dispatch("fetchMedicineQuestionAnalytics");
            }
        },
        commitSelectedHomeDate({ dispatch, commit }, selectedHomeDate) {
            commit(MutationTypes.ASSIGN_SELECTED_HOME_DATE, selectedHomeDate);
            // ホーム画面の対象月を切り替えたときに取得すべき情報を再取得
            dispatch("fetchRegistrationCounts");
            dispatch("fetchUsageCounts");
        },
        commitSelectedMedicalInformationDate({ dispatch, commit }, selectedMedicalInformationDate) {
            commit(MutationTypes.ASSIGN_SELECTED_MEDICAL_INFORMATION_DATE, selectedMedicalInformationDate);
            dispatch("fetchMedicalInformations");
        },
        commitSelectedMonthlyReportMailDate({ dispatch, commit }, selectedMonthlyReportMailDate) {
            commit(MutationTypes.ASSIGN_SELECTED_MONTHLY_REPORT_MAIL_DATE, selectedMonthlyReportMailDate);
            dispatch("fetchMonthlyReportMail");
        },
        commitSelectedComplaintsDate({ dispatch, commit }, selectedComplaintsDate) {
            commit(MutationTypes.ASSIGN_SELECTED_COMPLAINTS_DATE, selectedComplaintsDate);
            dispatch("fetchComplaints");
            dispatch("fetchComplaintPublicAvailability");
        },
        commitSelectedQuestionnaireDate({ dispatch, commit }, selectedQuestionnaireDate) {
            commit(MutationTypes.ASSIGN_SELECTED_QUESTIONNAIRE_DATE, selectedQuestionnaireDate);
            dispatch("fetchAfterUseQuestionnaires");
            dispatch("fetchAfterUseQuestionnairePublicAvailability");
        },
        commitSelectedQuestionnaireAnalyticsDate({ dispatch, commit }, selectedQuestionnaireAnalyticsDate) {
            commit(MutationTypes.ASSIGN_SELECTED_QUESTIONNAIRE_ANALYTICS_DATE, selectedQuestionnaireAnalyticsDate);
            dispatch("fetchAnytimeConsultationCount");
            dispatch("fetchNightConsultationCount");
            dispatch("fetchDaytimeAdviceCount");
        },
        commitSelectedMedicineQuestionAnalyticsDate({ dispatch, commit }, selectedMedicineQuestionAnalyticsDate) {
            commit(MutationTypes.ASSIGN_SELECTED_MEDICINE_QUESTION_ANALYTICS_DATE, selectedMedicineQuestionAnalyticsDate);
            dispatch("fetchMedicineQuestionAnalytics");
        },
        commitSelectedYearlySummaryStartDate({ commit }, selectedYearlySummaryStartDate) {
            commit(MutationTypes.ASSIGN_SELECTED_YEARLY_SUMMARY_START_DATE, selectedYearlySummaryStartDate);
        },
        commitSelectedYearlySummaryEndDate({ commit }, selectedYearlySummaryEndDate) {
            commit(MutationTypes.ASSIGN_SELECTED_YEARLY_SUMMARY_END_DATE, selectedYearlySummaryEndDate);
        },
        commitSelectedTaskStatusDate({ dispatch, commit }, selectedTaskStatusDate) {
            commit(MutationTypes.ASSIGN_SELECTED_TASK_STATUS_DATE, selectedTaskStatusDate);
            dispatch("fetchTaskStatuses");
        },
        resetSelectedValuesState({ commit }) {
            commit(MutationTypes.RESET_SELECTED_VALUES_STATE);
        },
    },
    mutations: {
        [MutationTypes.ASSIGN_SELECTED_CORPORATION_ID](state, selectedCorporationId) {
            state.selectedCorporationId = selectedCorporationId;
        },
        [MutationTypes.ASSIGN_SELECTED_HOME_DATE](state, selectedHomeDate) {
            state.selectedHomeDate = selectedHomeDate;
        },
        [MutationTypes.ASSIGN_SELECTED_YEARLY_SUMMARY_START_DATE](state, selectedYearlySummaryStartDate) {
            state.selectedYearlySummaryStartDate = selectedYearlySummaryStartDate;
        },
        [MutationTypes.ASSIGN_SELECTED_YEARLY_SUMMARY_END_DATE](state, selectedYearlySummaryEndDate) {
            state.selectedYearlySummaryEndDate = selectedYearlySummaryEndDate;
        },
        [MutationTypes.ASSIGN_SELECTED_MEDICAL_INFORMATION_DATE](state, selectedMedicalInformationDate) {
            state.selectedMedicalInformationDate = selectedMedicalInformationDate;
        },
        [MutationTypes.ASSIGN_SELECTED_MONTHLY_REPORT_MAIL_DATE](state, selectedMonthlyReportMailDate) {
            state.selectedMonthlyReportMailDate = selectedMonthlyReportMailDate;
        },
        [MutationTypes.ASSIGN_SELECTED_COMPLAINTS_DATE](state, selectedComplaintsDate) {
            state.selectedComplaintsDate = selectedComplaintsDate;
        },
        [MutationTypes.ASSIGN_SELECTED_QUESTIONNAIRE_DATE](state, selectedQuestionnaireDate) {
            state.selectedQuestionnaireDate = selectedQuestionnaireDate;
        },
        [MutationTypes.ASSIGN_SELECTED_QUESTIONNAIRE_ANALYTICS_DATE](state, selectedQuestionnaireAnalyticsDate) {
            state.selectedQuestionnaireAnalyticsDate = selectedQuestionnaireAnalyticsDate;
        },
        [MutationTypes.ASSIGN_SELECTED_MEDICINE_QUESTION_ANALYTICS_DATE](state, selectedMedicineQuestionAnalyticsDate) {
            state.selectedMedicineQuestionAnalyticsDate = selectedMedicineQuestionAnalyticsDate;
        },
        [MutationTypes.ASSIGN_SELECTED_TASK_STATUS_DATE](state, selectedTaskStatusDate) {
            state.selectedTaskStatusDate = selectedTaskStatusDate;
        },
        [MutationTypes.RESET_SELECTED_VALUES_STATE](state) {
            Object.assign(state, getDefaultState());
        },
    },
};
export default selectedValues;
