<template>
  <v-navigation-drawer
    app
    permanent
  >
    <v-container class="side-nav">
      <v-list>
        <v-list-item
          :to="{ name: 'main'}"
          link
          class="list-home"
          :class="route.path == '/' ? 'home-active' : ''"
        >
          <v-list-item-title>
            ホーム
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          :to="{ path: role.isSales() ? '/complaints_edit' : '/complaints'}"
          link
        >
          <v-list-item-title>
            主訴一覧
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          :to="{ path: role.isSales() ? '/questionnaires_edit' : '/questionnaires'}"
          link
        >
          <v-list-item-title>
            アンケート
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          :to="{ path: '/questionnaires_analytics'}"
          link
        >
          <v-list-item-title>
            詳細データ
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          :to="{ path: '/yearly_summary' }"
          link
        >
          <v-list-item-title>
            年度別データ
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          :to="{ path: '/medical_informations'}"
          link
        >
          <v-list-item-title>
            医療情報発信
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="corporation && corporation.medicineQuestionAvailable"
          :to="{ path: '/medicine_question_analytics'}"
          link
        >
          <v-list-item-title>
            くすりぼ利用
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="role.isSales()"
          link
          :to="{ path: '/send_monthly_mail'}"
        >
          <v-list-item-title>
            メール送信
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-container>
    <v-footer class="copyright">
      ©2021 Kids Public
    </v-footer>
  </v-navigation-drawer>
</template>
<script type="ts">
import { defineComponent, computed} from "vue"
import { roles } from "../../service/roles"
import { useStore } from "../../stores/index"
import { useRoute } from "vue-router"

export default defineComponent({
  name: "SideNavigationPart",
  setup() {
    // vuex
    const store = useStore()
    const route = useRoute()
    // data
    const currentUser = computed(() => store.getters.currentUser)
    const corporation = computed(() => store.getters.corporation)
    const role = roles()

    return {
      currentUser,
      role,
      corporation,
      route
    }
  }
})
</script>
<style lang="scss">
.side-nav {
  padding: 12px 0px 0px 12px;
  height: 90%;
}
.copyright {
  height: 10%;
  font-size: 0.79rem;
}
/** NOTE: 暫定対応 vuetify側の不具合が修正されたら対応をもとに戻す */
.list-home.v-list-item--active > .v-list-item__overlay {
  opacity: 0;
}
.list-home.home-active > .v-list-item__overlay {
  opacity: calc(0.12 * var(--v-theme-overlay-multiplier));
}
</style>
