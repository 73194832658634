import axios from "./index";
const callCorporationsInChargeAPI = (headers, successCallback, failureCallback) => {
    axios.get("/api/v1/corporations/in_charge", { headers: headers }).then((response) => {
        successCallback(response.headers, response.data);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
const callCorporationAPI = (headers, corporationId, successCallback, failureCallback) => {
    axios.get(`/api/v1/corporations/${corporationId}`, { headers: headers }).then((response) => {
        successCallback(response.headers, response.data);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
const callCorporationMonthlyMailRecipientsAPI = (headers, corporationId, successCallback, failureCallback) => {
    axios.get(`/api/v1/corporations/${corporationId}/monthly_mail_recipients`, { headers: headers }).then((response) => {
        successCallback(response.headers, response.data);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
const callCorporationsNestedTreeAPI = (headers, successCallback, failureCallback) => {
    axios.get("/api/v1/corporations/nested_tree", { headers: headers }).then((response) => {
        successCallback(response.headers, response.data);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
export { callCorporationsInChargeAPI, callCorporationAPI, callCorporationMonthlyMailRecipientsAPI, callCorporationsNestedTreeAPI };
