import { defineComponent, computed } from "vue";
export default defineComponent({
    name: "UnderstandingAnalyticsPart",
    props: {
        understandingProp: {
            type: Object,
            required: true
        },
    },
    setup(props) {
        const understanding = computed(() => props.understandingProp);
        return {
            understanding
        };
    }
});
