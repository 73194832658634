import { defineComponent, computed, onMounted } from "vue";
import { useStore } from "../../stores/index";
import monthPicker from "./MonthPicker.vue";
export default defineComponent({
    name: "ResponseResultsPart",
    components: {
        monthPicker
    },
    setup() {
        // vuex
        const store = useStore();
        // data
        const currentUser = computed(() => store.getters.currentUser);
        const selectedDate = computed(() => new Date(store.getters.selectedHomeDate));
        const allRegistrationCount = computed(() => store.getters.allRegistrationCount);
        const targetMonthRegistrationCount = computed(() => store.getters.targetMonthRegistrationCount);
        const targetMonthSyounikaRegistrationCount = computed(() => store.getters.targetMonthSyounikaRegistrationCount);
        const targetMonthObstetricsRegistrationCount = computed(() => store.getters.targetMonthObstetricsRegistrationCount);
        const countOfUsages = computed(() => store.getters.countOfUsages);
        const saveSelectedDate = async (selectedValue) => {
            await store.dispatch("commitSelectedHomeDate", selectedValue);
        };
        const downloadCSV = async () => {
            await store.dispatch("downloadResponseResults");
        };
        onMounted(() => {
            store.dispatch("commitSelectedHomeDate", selectedDate.value);
        });
        return {
            currentUser,
            selectedDate,
            allRegistrationCount,
            targetMonthRegistrationCount,
            targetMonthSyounikaRegistrationCount,
            targetMonthObstetricsRegistrationCount,
            saveSelectedDate,
            downloadCSV,
            countOfUsages,
        };
    }
});
