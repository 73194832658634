import { defineComponent, computed, ref } from "vue";
import { useStore } from "../../stores/index";
import { textFormatter } from "../../service/text_formatter";
export default defineComponent({
    name: "MonthlyMailViewPart",
    setup() {
        const store = useStore();
        const newLineCodeToBr = textFormatter().newLineCodeToBr;
        // data
        const mailCc = ref("business@kids-public.co.jp");
        const monthlyReportMail = computed(() => store.getters.monthlyReportMail);
        const editMail = () => {
            store.dispatch("commitMonthlyReportMailEditable", true);
        };
        const cancelMail = () => {
            if (confirm("メール送信をキャンセルします。よろしいですか？")) {
                store.dispatch("cancelMonthlyReportMail", monthlyReportMail.value.id);
            }
        };
        const sendMail = () => {
            store.dispatch("fixMonthlyReportMail", monthlyReportMail.value);
        };
        return {
            monthlyReportMail,
            mailCc,
            cancelMail,
            newLineCodeToBr,
            editMail,
            sendMail
        };
    }
});
