import axios from "axios";
// 共通ヘッダの定義などを行う
const token = document.getElementsByName("csrf-token")[0].getAttribute("content");
if (token != null) {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
}
axios.defaults.headers.common["Accept"] = "application/json";
export default axios;
// APIに関する定数
export const HTTP_STATUS_NO_CONTENT = 204;
export const HTTP_STATUS_UNPROCESSABLE_ENTITY = 422;
