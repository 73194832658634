import { defineComponent, onMounted } from "vue";
import { useStore } from "../stores/index";
import appBar from "./shared/AppBar.vue";
import sideNavigation from "./shared/SideNavigation.vue";
import { roles } from "../service/roles";
export default defineComponent({
    name: "TopPage",
    components: {
        appBar,
        sideNavigation
    },
    setup() {
        // vuex
        const store = useStore();
        const role = roles();
        onMounted(async () => {
            if (role.isSales()) {
                await store.dispatch("fetchCorporationsNestedTree");
            }
        });
    }
});
