import { defineComponent, computed } from "vue";
export default defineComponent({
    name: "TaskStatusRowPart",
    props: {
        taskStatus: {
            type: Object,
            required: true
        },
    },
    setup(props) {
        const statuses = computed(() => props.taskStatus);
        const statusLabel = (status) => {
            let label;
            if (isComplete(status)) {
                label = "done";
            }
            else if (isIncomplete(status)) {
                label = "未済";
            }
            else {
                label = "";
            }
            return label;
        };
        const isIncomplete = (status) => {
            return status == "incomplete";
        };
        const isComplete = (status) => {
            return status == "complete";
        };
        return {
            statuses,
            statusLabel,
            isIncomplete,
            isComplete
        };
    }
});
