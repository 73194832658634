import { defineComponent, onMounted, ref, computed } from "vue";
import relatedUrlDialog from "./RelatedUrlDialog.vue";
import router from "../../router/index";
import { useStore } from "../../stores/index";
import vueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { roles } from "../../service/roles";
export default defineComponent({
    name: "AppBarPart",
    components: {
        relatedUrlDialog,
        vueSelect
    },
    emits: ["logout"],
    setup() {
        // vuex
        const store = useStore();
        const corporations = computed(() => store.getters.corporationsInCharge);
        const selectedCorporationId = computed(() => store.getters.selectedCorporationId);
        const corporationId = ref(selectedCorporationId.value);
        const role = roles();
        onMounted(async () => {
            await store.dispatch("fetchCorporationsInCharge").then(() => {
                if (corporationId.value == -1) {
                    store.dispatch("commitSelectedCorporationId", store.getters.corporationsInCharge[0].id).then(() => {
                        // 明示的に設定し直す必要がありそう
                        corporationId.value = store.getters.selectedCorporationId;
                    });
                }
            });
        });
        const saveSelectedCorporation = async () => {
            await store.dispatch("commitSelectedCorporationId", corporationId.value).then(() => {
                // 法人を切り替えたらホームへ遷移する
                router.push("/");
            });
        };
        const cutomizePosition = (dropdownList, _component, { width, left }) => {
            dropdownList.classList.add("corporation-chooser");
            dropdownList.style.top = "51px";
            dropdownList.style.left = left;
            dropdownList.style.width = width;
        };
        return {
            corporations,
            corporationId,
            role,
            saveSelectedCorporation,
            cutomizePosition
        };
    }
});
