import axios from "./index";
const callSignInApi = (params, successCallback, failureCallback) => {
    axios.post("/api/v1/auth/sign_in", {
        email: params.email,
        password: params.password
    }).then((response) => {
        successCallback(response.headers, response.data.data);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
const callValidateTokenApi = (headers, successCallback, failureCallback) => {
    axios.get("/api/v1/auth/validate_token", { headers: headers }).then((response) => {
        successCallback(response.headers, response.data.success);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
const callSignOutApi = (headers, successCallback, failureCallback) => {
    axios.delete("/api/v1/auth/sign_out", { headers: headers }).then((response) => {
        successCallback(response.headers, response.data.success);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
export { callSignInApi, callSignOutApi, callValidateTokenApi };
