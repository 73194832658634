import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_app_bar = _resolveComponent("app-bar");
    const _component_side_navigation = _resolveComponent("side-navigation");
    const _component_router_view = _resolveComponent("router-view");
                                                          
    return (_openBlock(), _createElementBlock("div", null, [
        _createVNode(_component_app_bar),
        _createVNode(_component_side_navigation),
        _createVNode(_component_v_main, { id: "main-contant" }, {
            default: _withCtx(() => [
                _createVNode(_component_router_view)
            ]),
            _: 1
        })
    ]));
}


/* Vuetify */
import { VMain as _component_v_main } from "vuetify/lib/components/VMain/index.mjs"

