import * as MutationTypes from "../mutation_types";
import { callCorporationsInChargeAPI, callCorporationAPI, callCorporationMonthlyMailRecipientsAPI, callCorporationsNestedTreeAPI } from "../../api/corporations";
const getDefaultState = () => {
    return {
        corporation: null,
        corporationsInCharge: [],
        monthlyMailRecipients: [],
        corporationsNestedTree: [],
        normalCorporationsCount: 0
    };
};
const corporations = {
    state: () => getDefaultState(),
    getters: {
        corporation(state) {
            return state.corporation;
        },
        corporationsInCharge(state) {
            return state.corporationsInCharge;
        },
        monthlyMailRecipients(state) {
            return state.monthlyMailRecipients;
        },
        corporationsNestedTree(state) {
            return state.corporationsNestedTree;
        },
        normalCorporationsCount(state) {
            return state.normalCorporationsCount;
        },
    },
    actions: {
        fetchCorporation({ commit, getters }) {
            return new Promise((resolve, reject) => {
                callCorporationAPI(getters.authHeaders, getters.selectedCorporationId, 
                // Success
                (headers, corporation) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    commit(MutationTypes.ASSIGN_CORPORATION, corporation);
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        fetchCorporationsInCharge({ commit, getters }) {
            return new Promise((resolve, reject) => {
                callCorporationsInChargeAPI(getters.authHeaders, 
                // Success
                (headers, corporationsInCharge) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    commit(MutationTypes.ASSIGN_CORPORATIONS_IN_CHARGE, corporationsInCharge);
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        fetchMonthlyMailRecipients({ commit, getters }) {
            const currentUser = getters.currentUser;
            if (currentUser.type != "Sales")
                return;
            return new Promise((resolve, reject) => {
                callCorporationMonthlyMailRecipientsAPI(getters.authHeaders, getters.selectedCorporationId, 
                // Success
                (headers, monthlyMailRecipients) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    commit(MutationTypes.ASSIGN_MONTHLY_MAIL_RECIPIENTS, monthlyMailRecipients);
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        fetchCorporationsNestedTree({ dispatch, commit, getters }) {
            return new Promise((resolve, reject) => {
                callCorporationsNestedTreeAPI(getters.authHeaders, 
                // Success
                (headers, corporationsNestedTree) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    commit(MutationTypes.ASSIGN_CORPORATIONS_NESTED_TREE, corporationsNestedTree);
                    // 総企業数を取得しておく
                    dispatch("resetNormalCorporationsCount");
                    for (const corporation of corporationsNestedTree) {
                        dispatch("incrementNormalCorporationsCount", corporation);
                    }
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        incrementNormalCorporationsCount({ dispatch, commit, getters }, corporation) {
            const count = getters.normalCorporationsCount;
            commit(MutationTypes.ASSIGN_NORMAL_CORPORATIONS_COUNT, count + 1);
            for (const child of corporation.children) {
                dispatch("incrementNormalCorporationsCount", child);
            }
        },
        resetNormalCorporationsCount({ commit }) {
            commit(MutationTypes.ASSIGN_NORMAL_CORPORATIONS_COUNT, 0);
        },
        resetCorporationsState({ commit }) {
            commit(MutationTypes.RESET_CORPORATIONS_STATE);
        },
    },
    mutations: {
        [MutationTypes.ASSIGN_CORPORATIONS_IN_CHARGE](state, corporationsInCharge) {
            state.corporationsInCharge = corporationsInCharge;
        },
        [MutationTypes.ASSIGN_CORPORATION](state, corporation) {
            state.corporation = corporation;
        },
        [MutationTypes.ASSIGN_MONTHLY_MAIL_RECIPIENTS](state, monthlyMailRecipients) {
            state.monthlyMailRecipients = monthlyMailRecipients;
        },
        [MutationTypes.RESET_CORPORATIONS_STATE](state) {
            Object.assign(state, getDefaultState());
        },
        [MutationTypes.ASSIGN_CORPORATIONS_NESTED_TREE](state, corporationsNestedTree) {
            state.corporationsNestedTree = corporationsNestedTree;
        },
        [MutationTypes.ASSIGN_NORMAL_CORPORATIONS_COUNT](state, count) {
            state.normalCorporationsCount = count;
        },
    }
};
export default corporations;
