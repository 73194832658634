import axios from "./index";
const callYearlySummariesAPI = (headers, parameter, successCallback, failureCallback) => {
    axios.get("/api/v1/yearly_summaries.json", {
        headers: headers,
        params: {
            corporation_id: parameter.corporationId,
            start_date: parameter.startDate,
            end_date: parameter.endDate
        }
    }).then((response) => {
        successCallback(response.headers, response.data);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
const callDownloadYearlySummaryAPI = (headers, parameter, successCallback, failureCallback) => {
    axios.get("/api/v1/yearly_summaries.csv", {
        headers: headers,
        responseType: "blob",
        params: {
            corporation_id: parameter.corporationId,
            start_date: parameter.startDate,
            end_date: parameter.endDate
        }
    }).then((response) => {
        const mineType = response.headers["content-type"];
        const blob = new Blob([response.data], { type: mineType });
        successCallback(response.headers, blob);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
export { callYearlySummariesAPI, callDownloadYearlySummaryAPI };
