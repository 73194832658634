import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_latest_medical_informations = _resolveComponent("latest-medical-informations");
    const _component_response_results = _resolveComponent("response-results");
                                                                    
    return (_openBlock(), _createBlock(_component_v_container, null, {
        default: _withCtx(() => [
            _createVNode(_component_latest_medical_informations),
            _createVNode(_component_response_results)
        ]),
        _: 1
    }));
}


/* Vuetify */
import { VContainer as _component_v_container } from "vuetify/lib/components/VGrid/index.mjs"

