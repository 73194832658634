import { defineComponent, computed, onMounted, ref } from "vue";
import { useStore } from "../../stores/index";
export default defineComponent({
    name: "MonthlyMailEditPart",
    setup() {
        const store = useStore();
        const form = ref();
        // data
        const currentUser = computed(() => store.getters.currentUser);
        const formattedSendMailTo = computed(() => store.getters.monthlyMailRecipients.map((mailTo) => mailTo.formatted).join(","));
        const mailCc = ref("business@kids-public.co.jp");
        const monthlyReportMail = computed(() => store.getters.monthlyReportMail);
        const salesManagers = computed(() => store.getters.salesManagers);
        const salesManagerItems = salesManagers.value.map((manager) => ({ title: manager.formatted, value: manager.id }));
        const mailFrom = ref(currentUser.value.id);
        const formValid = ref(false);
        const sendMail = () => {
            // バリデーション
            monthlyReportMail.value.to = formattedSendMailTo.value;
            monthlyReportMail.value.from = salesManagers.value.find((manager) => manager.id == mailFrom.value).formatted;
            store.dispatch("fixMonthlyReportMail", monthlyReportMail.value);
        };
        const saveDraft = () => {
            monthlyReportMail.value.to = formattedSendMailTo.value;
            monthlyReportMail.value.from = salesManagers.value.find((manager) => manager.id == mailFrom.value).formatted;
            store.dispatch("saveDraftMonthlyReportMail", monthlyReportMail.value);
        };
        const required = (input) => {
            return !!input || "入力必須です";
        };
        onMounted(() => {
            store.dispatch("fetchSalesManagers");
            if (form.value) {
                form.value.validate();
            }
        });
        return {
            monthlyReportMail,
            formattedSendMailTo,
            mailCc,
            salesManagerItems,
            mailFrom,
            formValid,
            form,
            sendMail,
            saveDraft,
            required
        };
    }
});
