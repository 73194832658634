import axios from "./index";
const callCountOfUsagesAPI = (headers, parameter, successCallback, failureCallback) => {
    axios.get("/api/v1/response_results/count_of_usages", {
        headers: headers,
        params: {
            corporation_id: parameter.corporationId,
            target_month: parameter.targetMonth.toString()
        }
    }).then((response) => {
        successCallback(response.headers, response.data["countOfUsages"]);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
const callDownloadResponseResultsAPI = (headers, parameter, successCallback, failureCallback) => {
    axios.get("/api/v1/response_results.csv", {
        headers: headers,
        responseType: "blob",
        params: {
            corporation_id: parameter.corporationId,
            target_month: parameter.targetMonth.toString(),
        }
    }).then((response) => {
        const mineType = response.headers["content-type"];
        const blob = new Blob([response.data], { type: mineType });
        successCallback(response.headers, blob);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
const callCountRegistrationsAPI = (headers, parameter, successCallback, failureCallback) => {
    axios.get("/api/v1/response_results/count_registrations", {
        headers: headers,
        params: {
            corporation_id: parameter.corporationId,
            target_month: parameter.targetMonth.toString()
        }
    }).then((response) => {
        successCallback(response.headers, response.data["targetMonthRegistrationCount"], response.data["targetMonthSyounikaRegistrationCount"], response.data["targetMonthObstetricsRegistrationCount"]);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
const callTotalCountRegistrationsAPI = (headers, parameter, successCallback, failureCallback) => {
    axios.get("/api/v1/response_results/count_total_registrations", {
        headers: headers,
        params: {
            corporation_id: parameter.corporationId
        }
    }).then((response) => {
        successCallback(response.headers, response.data["allRegistrationCount"]);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
export { callCountOfUsagesAPI, callDownloadResponseResultsAPI, callCountRegistrationsAPI, callTotalCountRegistrationsAPI };
