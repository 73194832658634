import { createStore, useStore as baseUseStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
// moules
import currentUser from "./modules/current_user";
import corporations from "./modules/corporations";
import responseResults from "./modules/response_results";
import selectedValues from "./modules/selected_values";
import medicalInformations from "./modules/medical_informations";
import monthlyReportMails from "./modules/monthly_report_mails";
import managers from "./modules/managers";
import complaints from "./modules/complaints";
import afterUseQuestionnaires from "./modules/after_use_questionnaires";
import complaintPublicAvailabilities from "./modules/complaint_public_availabilities";
import afterUseQuestionnairePublicAvailabilities from "./modules/after_use_questionnaire_public_availabilities";
import questionnaireCount from "./modules/questionnaire_count";
import medicineQuestionAnalytics from "./modules/medicine_question_analytics";
import yearlySummaries from "./modules/yearly_summaries";
import taskStatuses from "./modules/task_statuses";
// storeをprovide/injectするためのキー
export const key = Symbol();
export const store = createStore({
    modules: {
        currentUser,
        corporations,
        responseResults,
        selectedValues,
        medicalInformations,
        monthlyReportMails,
        managers,
        complaints,
        afterUseQuestionnaires,
        complaintPublicAvailabilities,
        afterUseQuestionnairePublicAvailabilities,
        questionnaireCount,
        medicineQuestionAnalytics,
        yearlySummaries,
        taskStatuses
    },
    plugins: [createPersistedState({
            storage: window.sessionStorage
        })]
});
// useState を呼び出す度、 StateKey で型を定義するのを避けるために、ここであらかじめ定義する
export function useStore() {
    return baseUseStore(key);
}
