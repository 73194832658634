import * as MutationTypes from "../mutation_types";
import { callSalesAPI } from "../../api/managers";
const getDefaultState = () => {
    return {
        salesManagers: []
    };
};
const managers = {
    state: () => getDefaultState(),
    getters: {
        salesManagers(state) {
            return state.salesManagers;
        },
    },
    actions: {
        fetchSalesManagers({ commit, getters }) {
            return new Promise((resolve, reject) => {
                callSalesAPI(getters.authHeaders, 
                // Success
                (headers, salesManagers) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    commit(MutationTypes.ASSIGN_SALES_MANAGERS, salesManagers);
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        resetManagersState({ commit }) {
            commit(MutationTypes.RESET_MANAGERS_STATE);
        },
    },
    mutations: {
        [MutationTypes.ASSIGN_SALES_MANAGERS](state, salesManagers) {
            state.salesManagers = salesManagers;
        },
        [MutationTypes.RESET_MANAGERS_STATE](state) {
            Object.assign(state, getDefaultState());
        },
    },
};
export default managers;
