import * as MutationTypes from "../mutation_types";
import { callMedicalInformationsAPI, callLatestMedicalInformationsAPI } from "../../api/medical_informations";
const getDefaultState = () => {
    return {
        monthlyMedicalInformations: [],
        latestMedicalInformations: []
    };
};
const medicalInformations = {
    state: () => getDefaultState(),
    getters: {
        monthlyMedicalInformations(state) {
            return state.monthlyMedicalInformations;
        },
        latestMedicalInformations(state) {
            return state.latestMedicalInformations;
        },
    },
    actions: {
        fetchMedicalInformations({ commit, getters }) {
            return new Promise((resolve, reject) => {
                callMedicalInformationsAPI(getters.authHeaders, {
                    targetMonth: getters.selectedMedicalInformationDate
                }, 
                // Success
                (headers, results) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    commit(MutationTypes.ASSIGN_MONTHLY_MEDICAL_INFORMATIONS, results);
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        fetchLatestMedicalInformations({ commit, getters }) {
            return new Promise((resolve, reject) => {
                callLatestMedicalInformationsAPI(getters.authHeaders, 
                // Success
                (headers, results) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    commit(MutationTypes.ASSIGN_LATEST_MEDICAL_INFORMATIONS, results);
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        resetMedicalInformationsState({ commit }) {
            commit(MutationTypes.RESET_MEDICAL_INFORMATIONS_STATE);
        },
    },
    mutations: {
        [MutationTypes.ASSIGN_MONTHLY_MEDICAL_INFORMATIONS](state, results) {
            state.monthlyMedicalInformations = results;
        },
        [MutationTypes.ASSIGN_LATEST_MEDICAL_INFORMATIONS](state, results) {
            state.latestMedicalInformations = results;
        },
        [MutationTypes.RESET_MEDICAL_INFORMATIONS_STATE](state) {
            Object.assign(state, getDefaultState());
        },
    },
};
export default medicalInformations;
