/* eslint-disable no-irregular-whitespace */
const warningMessage = `

　　 ∩＿＿＿∩
　　 | ノ　　　　　 ヽ
　　/　　●　　　● |
　 |　　　　( ●)　 ミ
　彡､　　　|∪|　　､｀＼
/　＿＿　 ヽノ　/´>　 )
(＿＿＿）.f^f^f^f^f^f^f ┐
　|　　　　|~ ~ ~ ~ ~ ~ ~|
　|　　　　|　当月分だぞ 　|
　|　　／..| いいのか？ |
　|　/.　　| 本当にいいか？|
　∪ 　　 |.__＿＿＿＿＿_|
　　　　　　　 ＼＿)
`;
export { warningMessage };
/* eslint-enable no-irregular-whitespace */
