const dateUtility = () => {
    const nextMonth = () => {
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth() + 1, 1);
    };
    const prevMonth = () => {
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth() - 1, 1);
    };
    const fiscalYear = () => {
        // 前月が含まれる年度
        const fiscalYearDate = new Date(prevMonth().getFullYear(), prevMonth().getMonth() - 3, 1);
        return fiscalYearDate.getFullYear();
    };
    const isCurrentMonthSelected = (selectedDate) => {
        const date = new Date();
        return date.getFullYear() == selectedDate.getFullYear() &&
            date.getMonth() == selectedDate.getMonth();
    };
    return {
        nextMonth,
        prevMonth,
        fiscalYear,
        isCurrentMonthSelected
    };
};
export { dateUtility };
