import { createRouter, createWebHistory } from "vue-router";
import Home from "../components/Home.vue";
import Login from "../components/Login.vue";
import Main from "../components/Main.vue";
import ComplaintList from "../components/ComplaintList.vue";
import ComplaintsEdit from "../components/ComplaintsEdit.vue";
import QuestionnaireList from "../components/QuestionnaireList.vue";
import QuestionnairesEdit from "../components/QuestionnairesEdit.vue";
import YearlySummary from "../components/YearlySummary.vue";
import QuestionnaireAnalytics from "../components/QuestionnaireAnalytics.vue";
import MedicalInformationList from "../components/MedicalInformationList.vue";
import MonthlyMail from "../components/MonthlyMail.vue";
import MedicineQuestionAnalytics from "../components/MedicineQuestionAnalytics.vue";
import TaskStatus from "../components/TaskStatus.vue";
// Loginチェック
import { store } from "../stores/index";
const routes = [
    { path: "/",
        component: Home,
        meta: {
            requiresAuth: true
        },
        children: [{
                path: "",
                name: "main",
                component: Main
            },
            {
                path: "/complaints",
                name: "complaintList",
                component: ComplaintList
            },
            {
                path: "/complaints_edit",
                name: "complaintsEdit",
                component: ComplaintsEdit
            },
            {
                path: "/questionnaires",
                name: "questionnaireList",
                component: QuestionnaireList
            },
            {
                path: "/questionnaires_edit",
                name: "questionnairesEdit",
                component: QuestionnairesEdit
            },
            {
                path: "/questionnaires_analytics",
                name: "questionnaires_analytics",
                component: QuestionnaireAnalytics
            },
            {
                path: "/yearly_summary",
                name: "yearly_summary",
                component: YearlySummary
            },
            {
                path: "/medical_informations",
                name: "medicalInformationList",
                component: MedicalInformationList
            },
            {
                path: "/medicine_question_analytics",
                name: "medicineQuestionAnalytics",
                component: MedicineQuestionAnalytics
            },
            {
                path: "/send_monthly_mail",
                name: "sendMonthlyMail",
                component: MonthlyMail
            },
            {
                path: "/task_status",
                name: "taskStatus",
                component: TaskStatus
            }]
    },
    { path: "/login", component: Login },
];
const router = createRouter({
    history: createWebHistory(),
    routes,
});
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // ログインチェック
        store.dispatch("validateToken").then(() => {
            // ログインしている
            next();
        }).catch(() => {
            // ログインしていない
            next({
                path: "/login",
                query: {
                    redirectTo: to.fullPath
                }
            });
        });
    }
    else {
        next();
    }
});
export default router;
