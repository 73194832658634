import axios from "./index";
const callCountAnytimeConsultationQuestionnairesAPI = (headers, parameter, successCallback, failureCallback) => {
    axios.get("/api/v1/count_questionnaire/anytime_consultations", {
        headers: headers,
        params: {
            corporation_id: parameter.corporationId,
            target_month: parameter.targetMonth.toString()
        }
    }).then((response) => {
        successCallback(response.headers, response.data["countQuestionnaire"]);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
const callCountNightConsultationQuestionnairesAPI = (headers, parameter, successCallback, failureCallback) => {
    axios.get("/api/v1/count_questionnaire/night_consultations", {
        headers: headers,
        params: {
            corporation_id: parameter.corporationId,
            target_month: parameter.targetMonth.toString()
        }
    }).then((response) => {
        successCallback(response.headers, response.data["countQuestionnaire"]);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
const callCountDaytimeAdviceQuestionnairesAPI = (headers, parameter, successCallback, failureCallback) => {
    axios.get("/api/v1/count_questionnaire/daytime_advices", {
        headers: headers,
        params: {
            corporation_id: parameter.corporationId,
            target_month: parameter.targetMonth.toString()
        }
    }).then((response) => {
        successCallback(response.headers, response.data["countQuestionnaire"]);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
export { callCountAnytimeConsultationQuestionnairesAPI, callCountNightConsultationQuestionnairesAPI, callCountDaytimeAdviceQuestionnairesAPI };
