import axios from "./index";
const callMedicalInformationsAPI = (headers, parameter, successCallback, failureCallback) => {
    axios.get("/api/v1/medical_informations", {
        headers: headers,
        params: {
            target_month: parameter.targetMonth.toString()
        }
    }).then((response) => {
        successCallback(response.headers, response.data);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
const callLatestMedicalInformationsAPI = (headers, successCallback, failureCallback) => {
    axios.get("/api/v1/medical_informations/latest", { headers: headers }).then((response) => {
        successCallback(response.headers, response.data);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
export { callMedicalInformationsAPI, callLatestMedicalInformationsAPI };
