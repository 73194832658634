import { defineComponent, computed, onMounted } from "vue";
import { useStore } from "../stores/index";
import monthPicker from "./shared/MonthPicker.vue";
import useServiceAgainAnalytics from "./shared/UseServiceAgainAnalytics.vue";
import understandingAnalytics from "./shared/UnderstandingAnalytics.vue";
export default defineComponent({
    name: "QuestionnaireAnalyticsPage",
    components: {
        monthPicker,
        useServiceAgainAnalytics,
        understandingAnalytics
    },
    setup() {
        const store = useStore();
        const acQuestionnaireCount = computed(() => store.getters.anytimeConsultationQuestionnaireCount);
        const ncQuestionnaireCount = computed(() => store.getters.nightConsultationQuestionnaireCount);
        const daQuestionnaireCount = computed(() => store.getters.daytimeAdviceQuestionnaireCount);
        const selectedDate = computed(() => new Date(store.getters.selectedQuestionnaireAnalyticsDate));
        const saveSelectedDate = async (selectedValue) => {
            await store.dispatch("commitSelectedQuestionnaireAnalyticsDate", selectedValue);
        };
        onMounted(() => {
            store.dispatch("commitSelectedQuestionnaireAnalyticsDate", selectedDate.value);
        });
        return {
            selectedDate,
            saveSelectedDate,
            acQuestionnaireCount,
            ncQuestionnaireCount,
            daQuestionnaireCount
        };
    },
});
