import { defineComponent } from "vue";
import { useStore } from "./stores/index";
export default defineComponent({
    name: "App",
    setup() {
        // vuex
        const store = useStore();
        const logout = () => {
            store.dispatch("signOut");
        };
        return { logout };
    }
});
