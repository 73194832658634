import "../stylesheets/application.scss";
import { createApp } from "vue";
import router from "./router/index";
import { key, store } from "./stores/index";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import VueGtag from "vue-gtag";
loadFonts();
const app = createApp(App)
    .use(router)
    .use(vuetify)
    .use(store, key);
if (process.env.SERVER_ENV === "production") {
    app.use(VueGtag, {
        config: { id: "G-JCNVJ703TV" }
    }, router);
}
router.isReady().then(() => {
    app.mount("#vue-app");
});
