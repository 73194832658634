import * as MutationTypes from "../mutation_types";
import { callCountOfUsagesAPI, callCountRegistrationsAPI, callTotalCountRegistrationsAPI, callDownloadResponseResultsAPI } from "../../api/response_results";
import { saveAs } from "file-saver";
const getDefaultState = () => {
    return {
        countOfUsages: null,
        allRegistrationCount: -1,
        targetMonthRegistrationCount: -1,
        targetMonthSyounikaRegistrationCount: -1,
        targetMonthObstetricsRegistrationCount: -1
    };
};
const reseponseResults = {
    state: () => getDefaultState(),
    getters: {
        countOfUsages(state) {
            return state.countOfUsages;
        },
        allRegistrationCount(state) {
            return state.allRegistrationCount;
        },
        targetMonthRegistrationCount(state) {
            return state.targetMonthRegistrationCount;
        },
        targetMonthSyounikaRegistrationCount(state) {
            return state.targetMonthSyounikaRegistrationCount;
        },
        targetMonthObstetricsRegistrationCount(state) {
            return state.targetMonthObstetricsRegistrationCount;
        },
    },
    actions: {
        fetchUsageCounts({ commit, getters }) {
            if (getters.selectedCorporationId === -1)
                return;
            return new Promise((resolve, reject) => {
                // 指定企業の登録数取得
                callCountOfUsagesAPI(getters.authHeaders, {
                    corporationId: getters.selectedCorporationId,
                    targetMonth: getters.selectedHomeDate
                }, 
                // Success
                (headers, countOfUsages) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    commit(MutationTypes.ASSIGN_USAGES_COUNT, countOfUsages);
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        fetchRegistrationCounts({ commit, getters }) {
            if (getters.selectedCorporationId === -1)
                return;
            return new Promise((resolve, reject) => {
                // 指定企業の登録数取得
                callCountRegistrationsAPI(getters.authHeaders, {
                    corporationId: getters.selectedCorporationId,
                    targetMonth: getters.selectedHomeDate
                }, 
                // Success
                (headers, targetMonthRegistrationCount, targetMonthSyounikaRegistrationCount, targetMonthObstetricsRegistrationCount) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    commit(MutationTypes.ASSIGN_TARGET_MONTH_REGISTRATION_COUNT, targetMonthRegistrationCount);
                    commit(MutationTypes.ASSIGN_TARGET_MONTH_SYOUNIKA_REGISTRATION_COUNT, targetMonthSyounikaRegistrationCount);
                    commit(MutationTypes.ASSIGN_TARGET_MONTH_OBSTETRICS_REGISTRATION_COUNT, targetMonthObstetricsRegistrationCount);
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        fetchTotalRegistrationCounts({ commit, getters }) {
            if (getters.selectedCorporationId === -1)
                return;
            return new Promise((resolve, reject) => {
                // 指定企業の登録数取得
                // TODO 期間のパラメーターを後で追加する
                callTotalCountRegistrationsAPI(getters.authHeaders, {
                    corporationId: getters.selectedCorporationId
                }, 
                // Success
                (headers, allRegistrationCount) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    commit(MutationTypes.ASSIGN_ALL_REGISTRATION_COUNT, allRegistrationCount);
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        downloadResponseResults({ commit, getters }) {
            return new Promise((resolve, reject) => {
                callDownloadResponseResultsAPI(getters.authHeaders, {
                    corporationId: getters.selectedCorporationId,
                    targetMonth: getters.selectedHomeDate
                }, 
                // Success
                (headers, csvData) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    const targetMonth = getters.selectedHomeDate;
                    saveAs(csvData, `登録・利用状況_${targetMonth.getFullYear()}年${targetMonth.getMonth() + 1}月.csv`);
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        resetReseponseResultsState({ commit }) {
            commit(MutationTypes.RESET_RESPONSE_RESULT_STATE);
        },
    },
    mutations: {
        [MutationTypes.ASSIGN_USAGES_COUNT](state, countOfUsages) {
            state.countOfUsages = countOfUsages;
        },
        [MutationTypes.ASSIGN_ALL_REGISTRATION_COUNT](state, allRegistrationCount) {
            state.allRegistrationCount = allRegistrationCount;
        },
        [MutationTypes.ASSIGN_TARGET_MONTH_REGISTRATION_COUNT](state, targetMonthRegistrationCount) {
            state.targetMonthRegistrationCount = targetMonthRegistrationCount;
        },
        [MutationTypes.ASSIGN_TARGET_MONTH_SYOUNIKA_REGISTRATION_COUNT](state, targetMonthSyounikaRegistrationCount) {
            state.targetMonthSyounikaRegistrationCount = targetMonthSyounikaRegistrationCount;
        },
        [MutationTypes.ASSIGN_TARGET_MONTH_OBSTETRICS_REGISTRATION_COUNT](state, targetMonthObstetricsRegistrationCount) {
            state.targetMonthObstetricsRegistrationCount = targetMonthObstetricsRegistrationCount;
        },
        [MutationTypes.RESET_RESPONSE_RESULT_STATE](state) {
            Object.assign(state, getDefaultState());
        },
    },
};
export default reseponseResults;
