import { defineComponent, computed, onMounted, ref } from "vue";
import { useStore } from "../stores/index";
import { dateFormatter } from "../service/date_formatter";
import { textFormatter } from "../service/text_formatter";
import { warningMessage } from "../service/warning_message";
import { dateUtility } from "../service/date_utility";
import monthPicker from "./shared/MonthPicker.vue";
import confirmDialog from "./shared/ConfirmDialog.vue";
export default defineComponent({
    name: "QuestionnairesEditPage",
    components: {
        monthPicker,
        confirmDialog
    },
    setup() {
        // vuex
        const store = useStore();
        // data
        const corporation = computed(() => store.getters.corporation);
        const afterUseQuestionnaires = computed(() => store.getters.afterUseQuestionnaires);
        const published = computed(() => store.getters.afterUseQuestionnairesPublished);
        const afterUseQuestionnairePublicAvailability = computed(() => store.getters.afterUseQuestionnairePublicAvailability);
        const selectedDate = computed(() => new Date(store.getters.selectedQuestionnaireDate));
        const editable = ref(false);
        const dateFormat = dateFormatter();
        const newLineCodeToBr = textFormatter().newLineCodeToBr;
        const showDialog = ref(false);
        const dialogTitle = ref("");
        const dialogMessage = ref("");
        const isSyounika = (departmentType) => {
            if (departmentType == "pediatrician") {
                return true;
            }
            return false;
        };
        const saveSelectedDate = async (selectedValue) => {
            // 編集モードを解除する
            editable.value = false;
            await store.dispatch("commitSelectedQuestionnaireDate", selectedValue);
        };
        const fixed = () => {
            dialogTitle.value = "利用後アンケートの公開";
            let message = `【${corporation.value.name}】様の【${dateFormat.formatYearMonthJp(selectedDate.value)}】利用後アンケートを公開します。よろしいですか？`;
            if (dateUtility().isCurrentMonthSelected(selectedDate.value)) {
                message = message + warningMessage;
            }
            dialogMessage.value = message;
            openDialog();
        };
        const commitFixed = async () => {
            await store.dispatch("createAfterUseQuestionnairePublicAvailability");
            closeDialog();
        };
        const cancel = () => {
            dialogTitle.value = "利用後アンケートの非公開";
            let message = `【${corporation.value.name}】様の【${dateFormat.formatYearMonthJp(selectedDate.value)}】利用後アンケートを非公開にします。よろしいですか？`;
            dialogMessage.value = message;
            openDialog();
        };
        const commitCancel = async () => {
            await store.dispatch("deleteAfterUseQuestionnairePublicAvailability", afterUseQuestionnairePublicAvailability.value.id);
            closeDialog();
        };
        const edit = async () => {
            // 再読み込み
            await store.dispatch("fetchAfterUseQuestionnaires").then(() => {
                editable.value = true;
            });
        };
        const save = async () => {
            const params = [];
            afterUseQuestionnaires.value.forEach((auq) => {
                params.push({
                    id: auq.id,
                    requestOrOpinion: auq.requestOrOpinion
                });
            });
            await store.dispatch("updateAfterUseQuestionnaires", params).then(() => {
                editable.value = false;
            });
        };
        const reset = async () => {
            // 再読み込み
            await store.dispatch("fetchAfterUseQuestionnaires").then(() => {
                editable.value = false;
            });
        };
        const downloadCSV = async () => {
            await store.dispatch("downloadAfterUseQuestionnaires");
        };
        const openDialog = () => {
            showDialog.value = true;
        };
        const closeDialog = () => {
            showDialog.value = false;
        };
        onMounted(() => {
            store.dispatch("commitSelectedQuestionnaireDate", selectedDate.value);
        });
        return {
            selectedDate,
            afterUseQuestionnaires,
            published,
            editable,
            saveSelectedDate,
            fixed,
            cancel,
            edit,
            save,
            reset,
            downloadCSV,
            newLineCodeToBr,
            isSyounika,
            showDialog,
            dialogTitle,
            dialogMessage,
            commitFixed,
            commitCancel,
            closeDialog
        };
    }
});
