import axios from "./index";
const afterUseQuestionnairePublicAvailabilitiesCreateAPI = (headers, parameter, successCallback, failureCallback) => {
    axios.post("/api/v1/after_use_questionnaire_public_availabilities", {
        corporation_id: parameter.corporationId,
        target_month: parameter.targetMonth.toString()
    }, {
        headers: headers,
    }).then((response) => {
        successCallback(response.headers, response.data);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
const afterUseQuestionnairePublicAvailabilityDestroyAPI = (headers, id, successCallback, failureCallback) => {
    axios.delete(`/api/v1/after_use_questionnaire_public_availabilities/${id}`, {
        headers: headers,
    }).then((response) => {
        successCallback(response.headers, response.status);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
const callAfterUseQuestionnairePublicAvailabilityAPI = (headers, parameter, successCallback, failureCallback) => {
    axios.get("/api/v1/after_use_questionnaire_public_availabilities/find", {
        headers: headers,
        params: {
            corporation_id: parameter.corporationId,
            target_month: parameter.targetMonth.toString()
        }
    }).then((response) => {
        successCallback(response.headers, response.data);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
export { afterUseQuestionnairePublicAvailabilitiesCreateAPI, afterUseQuestionnairePublicAvailabilityDestroyAPI, callAfterUseQuestionnairePublicAvailabilityAPI };
