import axios from "./index";
const callComplaintPublishedAPI = (headers, parameter, successCallback, failureCallback) => {
    axios.get("/api/v1/task_status/complaint_published", {
        headers: headers,
        params: {
            corporation_id: parameter.corporationId,
            target_month: parameter.targetMonth.toString()
        }
    }).then((response) => {
        successCallback(response.headers, response.data["status"]);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
const callQuestionnairePublishedAPI = (headers, parameter, successCallback, failureCallback) => {
    axios.get("/api/v1/task_status/questionnaire_published", {
        headers: headers,
        params: {
            corporation_id: parameter.corporationId,
            target_month: parameter.targetMonth.toString()
        }
    }).then((response) => {
        successCallback(response.headers, response.data["status"]);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
const callMailSendAPI = (headers, parameter, successCallback, failureCallback) => {
    axios.get("/api/v1/task_status/mail_send", {
        headers: headers,
        params: {
            corporation_id: parameter.corporationId,
            target_month: parameter.targetMonth.toString()
        }
    }).then((response) => {
        successCallback(response.headers, response.data["status"]);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
const callDownloadTaskStatusAPI = (headers, parameter, successCallback, failureCallback) => {
    axios.get("/api/v1/task_status.csv", {
        headers: headers,
        responseType: "blob",
        params: {
            target_month: parameter.targetMonth.toString(),
        }
    }).then((response) => {
        const mineType = response.headers["content-type"];
        const blob = new Blob([response.data], { type: mineType });
        successCallback(response.headers, blob);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
export { callComplaintPublishedAPI, callQuestionnairePublishedAPI, callMailSendAPI, callDownloadTaskStatusAPI };
