import { defineComponent, ref } from "vue";
export default defineComponent({
    name: "RelatedUrlDialogPart",
    setup() {
        const dialog = ref(false);
        return {
            dialog
        };
    }
});
