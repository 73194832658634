import { defineComponent, computed, onMounted } from "vue";
import { useStore } from "../stores/index";
import monthlyMailView from "./shared/MonthlyMailView.vue";
import monthlyMailEdit from "./shared/MonthlyMailEdit.vue";
import monthPicker from "./shared/MonthPicker.vue";
export default defineComponent({
    name: "MonthlyMailPage",
    components: {
        monthlyMailView,
        monthlyMailEdit,
        monthPicker
    },
    setup() {
        const store = useStore();
        // data
        const corporation = computed(() => store.getters.corporation);
        const selectedDate = computed(() => new Date(store.getters.selectedMonthlyReportMailDate));
        const monthlyReportMail = computed(() => store.getters.monthlyReportMail);
        const monthlyReportMailEditable = computed(() => store.getters.monthlyReportMailEditable);
        const monthlyReportMailPostSuccess = computed(() => store.getters.monthlyReportMailPostSuccess);
        const monthlyReportMailErrors = computed(() => store.getters.monthlyReportMailErrors);
        const saveSelectedDate = async (selectedValue) => {
            await store.dispatch("commitSelectedMonthlyReportMailDate", selectedValue);
        };
        onMounted(() => {
            store.dispatch("commitSelectedMonthlyReportMailDate", selectedDate.value);
        });
        return {
            selectedDate,
            monthlyReportMail,
            corporation,
            saveSelectedDate,
            monthlyReportMailEditable,
            monthlyReportMailPostSuccess,
            monthlyReportMailErrors
        };
    }
});
