import { defineComponent, computed } from "vue";
export default defineComponent({
    name: "UseServiceAgainAnalyticsPart",
    props: {
        useServiceAgainProp: {
            type: Object,
            required: true
        },
    },
    setup(props) {
        const useServiceAgain = computed(() => props.useServiceAgainProp);
        return {
            useServiceAgain
        };
    }
});
