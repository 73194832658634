import { defineComponent, computed, onMounted } from "vue";
import { useStore } from "../stores/index";
import monthPicker from "./shared/MonthPicker.vue";
export default defineComponent({
    name: "MedicineQuestionAnalyticsPage",
    components: {
        monthPicker
    },
    setup() {
        const store = useStore();
        const pregnantUsages = computed(() => store.getters.medicineQuestionPregnantUsages);
        const afterChildbirthUsages = computed(() => store.getters.medicineQuestionAfterChildbirthUsages);
        const quickSurveys = computed(() => store.getters.medicineQuestionQuickSurveys);
        const selectedDate = computed(() => new Date(store.getters.selectedMedicineQuestionAnalyticsDate));
        const saveSelectedDate = async (selectedValue) => {
            await store.dispatch("commitSelectedMedicineQuestionAnalyticsDate", selectedValue);
        };
        onMounted(() => {
            store.dispatch("commitSelectedMedicineQuestionAnalyticsDate", selectedDate.value);
        });
        return {
            selectedDate,
            saveSelectedDate,
            pregnantUsages,
            afterChildbirthUsages,
            quickSurveys
        };
    },
});
