import axios from "./index";
const callSalesAPI = (headers, successCallback, failureCallback) => {
    axios.get("/api/v1/sales", {
        headers: headers
    }).then((response) => {
        successCallback(response.headers, response.data);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
export { callSalesAPI };
