import { defineComponent, ref } from "vue";
import datepicker from "vue3-datepicker";
import ja from "date-fns/locale/ja";
export default defineComponent({
    name: "MonthPickerPart",
    components: {
        datepicker
    },
    props: {
        selectedDate: {
            type: Date,
            required: true
        },
        maxMonth: Date,
        minMonth: Date,
    },
    emits: ["on-month-changed"],
    setup(props, { emit }) {
        const selectedValue = ref(props.selectedDate);
        const onMonthChange = (val) => {
            emit("on-month-changed", val);
        };
        const upperLimit = ref(props.maxMonth ? props.maxMonth : new Date());
        const lowerLimit = ref(props.minMonth ? props.minMonth : new Date("2022-03-01"));
        return {
            ja,
            selectedValue,
            upperLimit,
            lowerLimit,
            onMonthChange
        };
    }
});
