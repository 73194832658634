import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("td", null, [
            _createElementVNode("span", {
                class: _normalizeClass(_ctx.isIncomplete(_ctx.statuses.complaintPublishedStatus) ? 'text-red' : '')
            }, _toDisplayString(_ctx.statusLabel(_ctx.statuses.complaintPublishedStatus)), 3)
        ]),
        _createElementVNode("td", null, [
            _createElementVNode("span", {
                class: _normalizeClass(_ctx.isIncomplete(_ctx.statuses.questionnairePublishedStatus) ? 'text-red' : '')
            }, _toDisplayString(_ctx.statusLabel(_ctx.statuses.questionnairePublishedStatus)), 3)
        ]),
        _createElementVNode("td", null, [
            _createElementVNode("span", {
                class: _normalizeClass(_ctx.isIncomplete(_ctx.statuses.mailSendStatus) ? 'text-red' : '')
            }, _toDisplayString(_ctx.statusLabel(_ctx.statuses.mailSendStatus)), 3)
        ])
    ], 64));
}
