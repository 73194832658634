import * as MutationTypes from "../mutation_types";
import { callSignInApi, callSignOutApi, callValidateTokenApi } from "../../api/auth";
const getDefaultState = () => {
    return {
        currentUser: null,
        authHeaders: {
            "access-token": "",
            "token-type": "",
            client: "",
            expiry: "",
            uid: "",
            authorization: ""
        }
    };
};
const currentUser = {
    state: () => getDefaultState(),
    getters: {
        currentUser(state) {
            return state.currentUser;
        },
        authHeaders(state) {
            return state.authHeaders;
        },
    },
    actions: {
        signIn({ dispatch, commit }, params) {
            // store情報のクリア
            dispatch("resetState");
            return new Promise((resolve, reject) => {
                // ログイン処理
                callSignInApi(params, 
                // Success
                (headers, currentUser) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    commit(MutationTypes.ASSIGN_CURRENT_USER, currentUser);
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        signOut({ dispatch, commit, state }) {
            return new Promise((resolve, reject) => {
                // サインアウト
                callSignOutApi(state.authHeaders, 
                // Success
                (headers, result) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    if (result) {
                        dispatch("resetState");
                        resolve();
                    }
                    else {
                        reject();
                    }
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        validateToken({ commit, state }) {
            return new Promise((resolve, reject) => {
                // tokenの整合性チェック
                callValidateTokenApi(state.authHeaders, 
                // Success
                (headers, result) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    if (result) {
                        resolve();
                    }
                    else {
                        reject();
                    }
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        resetCurrentUserState({ commit }) {
            commit(MutationTypes.RESET_CURRENT_USER_STATE);
        },
        resetState({ dispatch }) {
            dispatch("resetAfterUseQuestionnairePublicAvailabilitiesState");
            dispatch("resetAfterUseQuestionnairesState");
            dispatch("resetComplaintPublicAvailabilitiesState");
            dispatch("resetComplaintsState");
            dispatch("resetCorporationsState");
            dispatch("resetCurrentUserState");
            dispatch("resetManagersState");
            dispatch("resetMedicalInformationsState");
            dispatch("resetMontylyReportMailsState");
            dispatch("resetQuestionnaireCountState");
            dispatch("resetSelectedValuesState");
            dispatch("resetReseponseResultsState");
            dispatch("resetCorporationsState");
            dispatch("resetTaskStatusesState");
        },
    },
    mutations: {
        [MutationTypes.ASSIGN_CURRENT_USER](state, currentUser) {
            state.currentUser = currentUser;
        },
        [MutationTypes.ASSIGN_AUTH_HEADERS](state, headers) {
            if (headers["access-token"]) {
                state.authHeaders = {
                    "access-token": headers["access-token"],
                    "token-type": headers["token-type"],
                    client: headers["client"],
                    expiry: headers["expiry"],
                    uid: headers["uid"],
                    authorization: headers["authorization"],
                };
            }
        },
        [MutationTypes.RESET_CURRENT_USER_STATE](state) {
            Object.assign(state, getDefaultState());
        },
    },
};
export default currentUser;
