import { defineComponent, computed, onMounted } from "vue";
import { useStore } from "../stores/index";
import monthPicker from "./shared/MonthPicker.vue";
export default defineComponent({
    name: "MedicalInformationListPage",
    components: {
        monthPicker
    },
    setup() {
        const store = useStore();
        // data
        const medicalInformations = computed(() => store.getters.monthlyMedicalInformations);
        const selectedDate = computed(() => new Date(store.getters.selectedMedicalInformationDate));
        const saveSelectedDate = async (selectedValue) => {
            await store.dispatch("commitSelectedMedicalInformationDate", selectedValue);
        };
        onMounted(() => {
            store.dispatch("commitSelectedMedicalInformationDate", selectedDate.value);
        });
        return {
            selectedDate,
            medicalInformations,
            saveSelectedDate
        };
    }
});
