import * as MutationTypes from "../mutation_types";
import { callMedicineQuestionAnalyticsAPI } from "../../api/medicine_question_analytics";
const getDefaultState = () => {
    return {
        medicineQuestionUsageAllCount: 0,
        medicineQuestionPregnantUsages: null,
        medicineQuestionAfterChildbirthUsages: null,
        medicineQuestionQuickSurveys: [],
    };
};
const medicineQuestionAnalytics = {
    state: () => getDefaultState(),
    getters: {
        medicineQuestionUsageAllCount(state) {
            return state.medicineQuestionUsageAllCount;
        },
        medicineQuestionPregnantUsages(state) {
            return state.medicineQuestionPregnantUsages;
        },
        medicineQuestionAfterChildbirthUsages(state) {
            return state.medicineQuestionAfterChildbirthUsages;
        },
        medicineQuestionQuickSurveys(state) {
            return state.medicineQuestionQuickSurveys;
        },
    },
    actions: {
        fetchMedicineQuestionAnalytics({ commit, getters }) {
            return new Promise((resolve, reject) => {
                callMedicineQuestionAnalyticsAPI(getters.authHeaders, {
                    corporationId: getters.selectedCorporationId,
                    targetMonth: getters.selectedMedicineQuestionAnalyticsDate
                }, 
                // Success
                (headers, medicineQuestionUsageAllCount, medicineQuestionPregnantUsages, medicineQuestionAfterChildbirthUsages, medicineQuestionQuickSurveys) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    commit(MutationTypes.ASSIGN_MEDICINE_QUESTION_USAGE_ALL_COUNT, medicineQuestionUsageAllCount);
                    commit(MutationTypes.ASSIGN_MEDICINE_QUESTION_PREGNANT_USAGES, medicineQuestionPregnantUsages);
                    commit(MutationTypes.ASSIGN_MEDICINE_QUESTION_AFTER_CHILDBIRTH_USAGES, medicineQuestionAfterChildbirthUsages);
                    commit(MutationTypes.ASSIGN_MEDICINE_QUESTION_QUICK_SURVEYS, medicineQuestionQuickSurveys);
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        resetCorporationsState({ commit }) {
            commit(MutationTypes.RESET_MEDICINE_QUESTION_ANALYTICS_STATE);
        }
    },
    mutations: {
        [MutationTypes.ASSIGN_MEDICINE_QUESTION_USAGE_ALL_COUNT](state, medicineQuestionUsageAllCount) {
            state.medicineQuestionUsageAllCount = medicineQuestionUsageAllCount;
        },
        [MutationTypes.ASSIGN_MEDICINE_QUESTION_PREGNANT_USAGES](state, medicineQuestionPregnantUsages) {
            state.medicineQuestionPregnantUsages = medicineQuestionPregnantUsages;
        },
        [MutationTypes.ASSIGN_MEDICINE_QUESTION_AFTER_CHILDBIRTH_USAGES](state, medicineQuestionAfterChildbirthUsages) {
            state.medicineQuestionAfterChildbirthUsages = medicineQuestionAfterChildbirthUsages;
        },
        [MutationTypes.ASSIGN_MEDICINE_QUESTION_QUICK_SURVEYS](state, medicineQuestionQuickSurveys) {
            state.medicineQuestionQuickSurveys = medicineQuestionQuickSurveys;
        },
        [MutationTypes.RESET_MEDICINE_QUESTION_ANALYTICS_STATE](state) {
            Object.assign(state, getDefaultState());
        },
    }
};
export default medicineQuestionAnalytics;
