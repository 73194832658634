import { defineComponent, computed, onMounted } from "vue";
import { useStore } from "../stores/index";
import { textFormatter } from "../service/text_formatter";
import monthPicker from "./shared/MonthPicker.vue";
import unpublishedMessage from "./shared/UnpublishedMessage.vue";
export default defineComponent({
    name: "ComplaintListPage",
    components: {
        monthPicker,
        unpublishedMessage
    },
    setup() {
        // vuex
        const store = useStore();
        const newLineCodeToBr = textFormatter().newLineCodeToBr;
        // data
        const complaints = computed(() => store.getters.complaints);
        const published = computed(() => store.getters.complaintsPublished);
        const selectedDate = computed(() => new Date(store.getters.selectedComplaintsDate));
        const isSyounika = (departmentType) => {
            if (departmentType == "pediatrician") {
                return true;
            }
            return false;
        };
        const saveSelectedDate = async (selectedValue) => {
            await store.dispatch("commitSelectedComplaintsDate", selectedValue);
        };
        const downloadCSV = async () => {
            await store.dispatch("downloadComplaints");
        };
        onMounted(() => {
            store.dispatch("commitSelectedComplaintsDate", selectedDate.value);
        });
        return {
            selectedDate,
            complaints,
            published,
            saveSelectedDate,
            newLineCodeToBr,
            isSyounika,
            downloadCSV
        };
    }
});
