import { useStore } from "../stores/index";
import { computed } from "vue";
const roles = () => {
    const store = useStore();
    const currentUser = computed(() => store.getters.currentUser);
    const isSales = () => {
        return currentUser.value.type === "Sales";
    };
    const isAgency = () => {
        return currentUser.value.type === "Agency";
    };
    const isClient = () => {
        return currentUser.value.type === "Client";
    };
    return {
        isSales,
        isAgency,
        isClient
    };
};
export { roles };
