import * as MutationTypes from "../mutation_types";
import { callYearlySummariesAPI, callDownloadYearlySummaryAPI } from "../../api/yearly_summaries";
import { saveAs } from "file-saver";
const getDefaultState = () => {
    return {
        yearlySummaryRows: []
    };
};
const yearlySummaries = {
    state: () => getDefaultState(),
    getters: {
        YearlySummaryRows(state) {
            return state.yearlySummaryRows;
        },
    },
    actions: {
        fetchYearlySummary({ commit, getters }) {
            if (getters.selectedCorporationId === -1)
                return;
            commit(MutationTypes.ASSIGN_YEARLY_SUMMARY, []);
            return new Promise((resolve, reject) => {
                // 指定企業の登録数取得
                callYearlySummariesAPI(getters.authHeaders, {
                    corporationId: getters.selectedCorporationId,
                    startDate: getters.selectedYearlySummaryStartDate,
                    endDate: getters.selectedYearlySummaryEndDate,
                }, 
                // Success
                (headers, yearlySummaryRows) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    commit(MutationTypes.ASSIGN_YEARLY_SUMMARY, yearlySummaryRows);
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        downloadYearlySummary({ commit, getters }) {
            return new Promise((resolve, reject) => {
                callDownloadYearlySummaryAPI(getters.authHeaders, {
                    corporationId: getters.selectedCorporationId,
                    startDate: getters.selectedYearlySummaryStartDate,
                    endDate: getters.selectedYearlySummaryEndDate,
                }, 
                // Success
                (headers, csvData) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    saveAs(csvData, "年度別データ.csv");
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
    },
    mutations: {
        [MutationTypes.ASSIGN_YEARLY_SUMMARY](state, yearlySummaryRows) {
            state.yearlySummaryRows = yearlySummaryRows;
        },
    },
};
export default yearlySummaries;
