<template>
  <v-row justify="center">
    <v-dialog
      v-model="isActive"
      persistent
      width="auto"
    >
      <v-card class="pa-4">
        <v-card-title class="text-h5">
          {{ title }}
        </v-card-title>
        <v-card-text style="white-space:pre">
          {{ message }}
        </v-card-text>
        <v-divider />
        <v-card-text class="d-flex">
          <v-spacer />
          <v-btn
            color="darken-1"
            class="mx-2"
            @click="$emit('onClickDialogCancel')"
          >
            キャンセル
          </v-btn>
          <v-btn
            color="primary"
            class="mx-2"
            @click="$emit('onClickDialogOk')"
          >
            OK
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { defineComponent, computed } from "vue"

export default defineComponent({
  name: "ConfirmDialogView",
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    dialogTitle: {
      type: String,
      required: true
    },
    dialogMessage: {
      type: String,
      required: true
    },
  },
  emits: ["onClickDialogOk", "onClickDialogCancel"],
  setup(props) {
    const isActive = computed(() => props.showDialog)
    const title = computed(() => props.dialogTitle)
    const message = computed(() => props.dialogMessage)

    return {
      isActive,
      title,
      message
    }
  }
})
</script>
