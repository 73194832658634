import * as MutationTypes from "../mutation_types";
import { callCountAnytimeConsultationQuestionnairesAPI, callCountNightConsultationQuestionnairesAPI, callCountDaytimeAdviceQuestionnairesAPI } from "../../api/count_questionnaire";
const getDefaultState = () => {
    return {
        anytimeConsultationQuestionnaireCount: null,
        nightConsultationQuestionnaireCount: null,
        daytimeAdviceQuestionnaireCount: null
    };
};
const questionnaireCount = {
    state: () => getDefaultState(),
    getters: {
        anytimeConsultationQuestionnaireCount(state) {
            return state.anytimeConsultationQuestionnaireCount;
        },
        nightConsultationQuestionnaireCount(state) {
            return state.nightConsultationQuestionnaireCount;
        },
        daytimeAdviceQuestionnaireCount(state) {
            return state.daytimeAdviceQuestionnaireCount;
        },
    },
    actions: {
        fetchAnytimeConsultationCount({ commit, getters }) {
            return new Promise((resolve, reject) => {
                callCountAnytimeConsultationQuestionnairesAPI(getters.authHeaders, {
                    corporationId: getters.selectedCorporationId,
                    targetMonth: getters.selectedQuestionnaireAnalyticsDate
                }, 
                // Success
                (headers, anytimeConsultationQuestionnaireCount) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    commit(MutationTypes.ASSIGN_ANYTIME_CONSULTATION_QUESTIONNAIRE_COUNT, anytimeConsultationQuestionnaireCount);
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        fetchNightConsultationCount({ commit, getters }) {
            return new Promise((resolve, reject) => {
                callCountNightConsultationQuestionnairesAPI(getters.authHeaders, {
                    corporationId: getters.selectedCorporationId,
                    targetMonth: getters.selectedQuestionnaireAnalyticsDate
                }, 
                // Success
                (headers, nightConsultationQuestionnaireCount) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    commit(MutationTypes.ASSIGN_NIGHT_CONSULTATION_QUESTIONNAIRE_COUNT, nightConsultationQuestionnaireCount);
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        fetchDaytimeAdviceCount({ commit, getters }) {
            return new Promise((resolve, reject) => {
                callCountDaytimeAdviceQuestionnairesAPI(getters.authHeaders, {
                    corporationId: getters.selectedCorporationId,
                    targetMonth: getters.selectedQuestionnaireAnalyticsDate
                }, 
                // Success
                (headers, daytimeAdviceQuestionnaireCount) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    commit(MutationTypes.ASSIGN_DAYTIME_ADVICE_QUESTIONNAIRE_COUNT, daytimeAdviceQuestionnaireCount);
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        resetQuestionnaireCountState({ commit }) {
            commit(MutationTypes.RESET_QUESTIONNAIRE_COUNT_STATE);
        },
    },
    mutations: {
        [MutationTypes.ASSIGN_ANYTIME_CONSULTATION_QUESTIONNAIRE_COUNT](state, anytimeConsultationQuestionnaireCount) {
            state.anytimeConsultationQuestionnaireCount = anytimeConsultationQuestionnaireCount;
        },
        [MutationTypes.ASSIGN_NIGHT_CONSULTATION_QUESTIONNAIRE_COUNT](state, nightConsultationQuestionnaireCount) {
            state.nightConsultationQuestionnaireCount = nightConsultationQuestionnaireCount;
        },
        [MutationTypes.ASSIGN_DAYTIME_ADVICE_QUESTIONNAIRE_COUNT](state, daytimeAdviceQuestionnaireCount) {
            state.daytimeAdviceQuestionnaireCount = daytimeAdviceQuestionnaireCount;
        },
        [MutationTypes.RESET_QUESTIONNAIRE_COUNT_STATE](state) {
            Object.assign(state, getDefaultState());
        },
    },
};
export default questionnaireCount;
