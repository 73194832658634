import * as MutationTypes from "../mutation_types";
import { callMonthlyReportMailsFindAPI, callFixMonthlyReportMailsAPI, callSaveDraftMonthlyReportMailsAPI, callCancelMonthlyReportMailAPI } from "../../api/monthly_report_mails";
import { HTTP_STATUS_NO_CONTENT, HTTP_STATUS_UNPROCESSABLE_ENTITY } from "../../api/index";
const getDefaultState = () => {
    return {
        monthlyReportMail: null,
        monthlyReportMailPostSuccess: null,
        monthlyReportMailErrors: []
    };
};
const monthlyReportMails = {
    state: () => getDefaultState(),
    getters: {
        monthlyReportMail(state) {
            return state.monthlyReportMail;
        },
        monthlyReportMailPostSuccess(state) {
            return state.monthlyReportMailPostSuccess;
        },
        monthlyReportMailEditable(state) {
            return state.monthlyReportMailEditable;
        },
        monthlyReportMailErrors(state) {
            return state.monthlyReportMailErrors;
        },
    },
    actions: {
        fetchMonthlyReportMail({ commit, getters }) {
            const currentUser = getters.currentUser;
            if (currentUser.type != "Sales")
                return;
            return new Promise((resolve, reject) => {
                callMonthlyReportMailsFindAPI(getters.authHeaders, {
                    corporationId: getters.selectedCorporationId,
                    targetMonth: getters.selectedMonthlyReportMailDate
                }, 
                // Success
                (headers, monthlyReportMail) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    commit(MutationTypes.ASSIGN_MONTHLY_REPORT_MAIL, monthlyReportMail);
                    // 新規作成時のみ編集画面を表示する
                    if (monthlyReportMail.newRecord) {
                        commit(MutationTypes.ASSIGN_MONTHLY_REPORT_MAIL_EDITABLE, true);
                    }
                    else {
                        commit(MutationTypes.ASSIGN_MONTHLY_REPORT_MAIL_EDITABLE, false);
                    }
                    // エラーメッセージのクリア
                    commit(MutationTypes.ASSIGN_POST_MONTHLY_REPORT_MAIL_RESULT, true);
                    commit(MutationTypes.ASSIGN_MONTHLY_REPORT_MAIL_ERRORS, []);
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        fixMonthlyReportMail({ dispatch, commit, getters }, monthlyReportMail) {
            return new Promise((resolve, reject) => {
                callFixMonthlyReportMailsAPI(getters.authHeaders, monthlyReportMail, 
                // Success
                (headers, status) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    let result = false;
                    if (status == HTTP_STATUS_NO_CONTENT) {
                        result = true;
                    }
                    commit(MutationTypes.ASSIGN_POST_MONTHLY_REPORT_MAIL_RESULT, result);
                    dispatch("fetchMonthlyReportMail");
                    resolve();
                }, 
                // Failure
                (error) => {
                    if (error.response?.headers) {
                        commit(MutationTypes.ASSIGN_AUTH_HEADERS, error.response.headers);
                    }
                    commit(MutationTypes.ASSIGN_POST_MONTHLY_REPORT_MAIL_RESULT, false);
                    if (error.response?.status == HTTP_STATUS_UNPROCESSABLE_ENTITY) {
                        // バリデーションエラー
                        commit(MutationTypes.ASSIGN_MONTHLY_REPORT_MAIL_ERRORS, error.response?.data.messages);
                        resolve();
                    }
                    else {
                        reject();
                    }
                });
            });
        },
        saveDraftMonthlyReportMail({ dispatch, commit, getters }, monthlyReportMail) {
            return new Promise((resolve, reject) => {
                callSaveDraftMonthlyReportMailsAPI(getters.authHeaders, monthlyReportMail, 
                // Success
                (headers, status) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    let result = false;
                    if (status == HTTP_STATUS_NO_CONTENT) {
                        result = true;
                    }
                    commit(MutationTypes.ASSIGN_POST_MONTHLY_REPORT_MAIL_RESULT, result);
                    dispatch("fetchMonthlyReportMail");
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        cancelMonthlyReportMail({ dispatch, commit, getters }, monthlyReportMailId) {
            return new Promise((resolve, reject) => {
                callCancelMonthlyReportMailAPI(getters.authHeaders, monthlyReportMailId, 
                // Success
                (headers, status) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    let result = false;
                    if (status == HTTP_STATUS_NO_CONTENT) {
                        result = true;
                    }
                    commit(MutationTypes.ASSIGN_POST_MONTHLY_REPORT_MAIL_RESULT, result);
                    dispatch("fetchMonthlyReportMail");
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        commitMonthlyReportMailEditable({ commit }, editable) {
            commit(MutationTypes.ASSIGN_MONTHLY_REPORT_MAIL_EDITABLE, editable);
        },
        resetMontylyReportMailsState({ commit }) {
            commit(MutationTypes.RESET_MONTHLY_REPORT_MAILS_STATE);
        },
    },
    mutations: {
        [MutationTypes.ASSIGN_MONTHLY_REPORT_MAIL](state, monthlyReportMail) {
            state.monthlyReportMail = monthlyReportMail;
        },
        [MutationTypes.ASSIGN_POST_MONTHLY_REPORT_MAIL_RESULT](state, result) {
            state.monthlyReportMailPostSuccess = result;
        },
        [MutationTypes.ASSIGN_MONTHLY_REPORT_MAIL_EDITABLE](state, editable) {
            state.monthlyReportMailEditable = editable;
        },
        [MutationTypes.ASSIGN_MONTHLY_REPORT_MAIL_ERRORS](state, messages) {
            state.monthlyReportMailErrors = messages;
        },
        [MutationTypes.RESET_MONTHLY_REPORT_MAILS_STATE](state) {
            Object.assign(state, getDefaultState());
        },
    },
};
export default monthlyReportMails;
