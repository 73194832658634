import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "../stores/index";
import router from "../router/index";
import { useRoute } from "vue-router";
export default defineComponent({
    name: "LoginPage",
    setup() {
        const store = useStore();
        const route = useRoute();
        const email = ref("");
        const password = ref("");
        const errorMessage = ref("");
        const redirectPath = ref("/");
        onMounted(() => {
            if (typeof route.query.redirectTo === "string") {
                redirectPath.value = route.query.redirectTo;
            }
        });
        const login = async () => {
            await store.dispatch("signIn", { email: email.value, password: password.value }).then(() => {
                router.push(redirectPath.value);
            }).catch(() => {
                errorMessage.value = "ログインに失敗しました。";
            });
        };
        return {
            email,
            password,
            errorMessage,
            login
        };
    }
});
