// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
// Vuetify
import { createVuetify } from "vuetify";
export default createVuetify({
    theme: {
        defaultTheme: "default",
        themes: {
            default: {
                dark: false,
                colors: {
                    background: "#F8F8F8",
                    "on-background": "#604A49",
                    "on-surface": "#604A49",
                    // ボタン系
                    primary: "#1976D2",
                    // surface: '#FFFFFF',
                    // 'primary-darken-1': '#3700B3',
                    // secondary: '#03DAC6',
                    // 'secondary-darken-1': '#018786',
                    // メッセージ系
                    // error: '#B00020',
                    // info: '#2196F3',
                    // success: '#4CAF50',
                    // warning: '#FB8C00',
                }
            }
        }
    }
}
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
);
