import { defineComponent, computed, onMounted } from "vue";
import { useStore } from "../stores/index";
import monthPicker from "./shared/MonthPicker.vue";
export default defineComponent({
    name: "YearlySummaryPage",
    components: {
        monthPicker
    },
    setup() {
        // vuex
        const store = useStore();
        const startDate = computed(() => new Date(store.getters.selectedYearlySummaryStartDate));
        const endDate = computed(() => new Date(store.getters.selectedYearlySummaryEndDate));
        const yearlySummaryRows = computed(() => store.getters.YearlySummaryRows);
        // 表を作成
        const isLoaded = computed(() => {
            return yearlySummaryRows.value instanceof Array && yearlySummaryRows.value.length > 0;
        });
        const monthArr = computed(() => {
            return yearlySummaryRows.value.map((row) => row.month);
        });
        const syounikaRegistrationArr = computed(() => {
            return yearlySummaryRows.value.map((row) => row.syounikaRegistration || 0);
        });
        const obstetricsRegistrationArr = computed(() => {
            return yearlySummaryRows.value.map((row) => row.obstetricsRegistration || 0);
        });
        const allRegistrationArr = computed(() => {
            return yearlySummaryRows.value.map((row) => row.allRegistration || 0);
        });
        const cumulativeRegistrationArr = computed(() => {
            return yearlySummaryRows.value.map((row) => row.cumulativeRegistration || 0);
        });
        const syounikaReservationArr = computed(() => {
            return yearlySummaryRows.value.map((row) => row.syounikaReservation || 0);
        });
        const obstetricsReservationArr = computed(() => {
            return yearlySummaryRows.value.map((row) => row.obstetricsReservation || 0);
        });
        const syounikaAnytimeConsultationArr = computed(() => {
            return yearlySummaryRows.value.map((row) => row.syounikaAnytimeConsultation || 0);
        });
        const obstetricsAnytimeConsultationArr = computed(() => {
            return yearlySummaryRows.value.map((row) => row.obstetricsAnytimeConsultation || 0);
        });
        const daytimeAdviceSheetArr = computed(() => {
            return yearlySummaryRows.value.map((row) => row.daytimeAdviceSheet || 0);
        });
        const medicineQuestionSelectionArr = computed(() => {
            return yearlySummaryRows.value.map((row) => row.medicineQuestionSelection || 0);
        });
        const allUseArr = computed(() => {
            return yearlySummaryRows.value.map((row) => row.allUse || 0);
        });
        const isValidDate = computed(() => {
            if (!(startDate.value instanceof Date) || !(endDate.value instanceof Date)) {
                return false;
            }
            if (startDate.value > endDate.value) {
                return false;
            }
            if (diffMonth(startDate.value, endDate.value) > 12) {
                return false;
            }
            return true;
        });
        const diffMonth = (startDateValue, endDateValue) => {
            const startMonth = startDateValue.getFullYear() * 12 + startDateValue.getMonth();
            const endMonth = endDateValue.getFullYear() * 12 + endDateValue.getMonth();
            return (endMonth - startMonth) + 1;
        };
        const isInvalidDate = computed(() => {
            return !(isValidDate.value);
        });
        const downloadCSV = async () => {
            await store.dispatch("downloadYearlySummary");
        };
        const reload = async () => {
            await store.dispatch("fetchYearlySummary");
        };
        const saveStartDate = async (selectedValue) => {
            await store.dispatch("commitSelectedYearlySummaryStartDate", selectedValue);
        };
        const saveEndDate = async (selectedValue) => {
            await store.dispatch("commitSelectedYearlySummaryEndDate", selectedValue);
        };
        const calcSum = (arr) => {
            return arr.reduce((a, b) => a + b);
        };
        onMounted(() => {
            store.dispatch("fetchYearlySummary");
        });
        return {
            startDate,
            endDate,
            yearlySummaryRows,
            monthArr,
            syounikaRegistrationArr,
            obstetricsRegistrationArr,
            allRegistrationArr,
            cumulativeRegistrationArr,
            syounikaReservationArr,
            obstetricsReservationArr,
            syounikaAnytimeConsultationArr,
            obstetricsAnytimeConsultationArr,
            daytimeAdviceSheetArr,
            medicineQuestionSelectionArr,
            allUseArr,
            isLoaded,
            isValidDate,
            isInvalidDate,
            calcSum,
            downloadCSV,
            reload,
            saveStartDate,
            saveEndDate
        };
    }
});
