import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_router_view = _resolveComponent("router-view");
                                                        
    return (_openBlock(), _createBlock(_component_v_app, null, {
        default: _withCtx(() => [
            _createVNode(_component_router_view, { onLogout: _ctx.logout }, null, 8, ["onLogout"])
        ]),
        _: 1
    }));
}


/* Vuetify */
import { VApp as _component_v_app } from "vuetify/lib/components/VApp/index.mjs"

