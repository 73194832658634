import axios from "./index";
const callMonthlyReportMailsFindAPI = (headers, parameter, successCallback, failureCallback) => {
    axios.get("/api/v1/monthly_report_mails/find", {
        headers: headers,
        params: {
            corporation_id: parameter.corporationId,
            target_month: parameter.targetMonth.toString()
        }
    }).then((response) => {
        successCallback(response.headers, response.data);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
const callFixMonthlyReportMailsAPI = (headers, monthlyReportMail, successCallback, failureCallback) => {
    axios.post("/api/v1/monthly_report_mails/fix", {
        monthly_report_mail: {
            corporation_id: monthlyReportMail.corporationId,
            from: monthlyReportMail.from,
            to: monthlyReportMail.to,
            year: monthlyReportMail.year,
            month: monthlyReportMail.month,
            title: monthlyReportMail.title,
            body: monthlyReportMail.body
        }
    }, {
        headers: headers,
    }).then((response) => {
        successCallback(response.headers, response.status);
    }).catch((error) => {
        failureCallback(error);
    });
};
const callSaveDraftMonthlyReportMailsAPI = (headers, monthlyReportMail, successCallback, failureCallback) => {
    axios.post("/api/v1/monthly_report_mails/save_draft", {
        monthly_report_mail: {
            corporation_id: monthlyReportMail.corporationId,
            from: monthlyReportMail.from,
            to: monthlyReportMail.to,
            year: monthlyReportMail.year,
            month: monthlyReportMail.month,
            title: monthlyReportMail.title,
            body: monthlyReportMail.body
        }
    }, {
        headers: headers,
    }).then((response) => {
        successCallback(response.headers, response.status);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
const callCancelMonthlyReportMailAPI = (headers, id, successCallback, failureCallback) => {
    axios.put(`/api/v1/monthly_report_mails/${id}/cancel`, {}, {
        headers: headers,
    }).then((response) => {
        successCallback(response.headers, response.status);
    }).catch((error) => {
        console.error(error);
        failureCallback();
    });
};
export { callMonthlyReportMailsFindAPI, callFixMonthlyReportMailsAPI, callSaveDraftMonthlyReportMailsAPI, callCancelMonthlyReportMailAPI };
